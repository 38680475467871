import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createFileRoute,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import moment from "moment";
import { useState } from "react";

export const Route = createFileRoute("/_application/assets/domains/")({
  component: Domains,
  loaderDeps: ({ search }) => {
    return {
      search,
    };
  },
  loader: ({ context, deps, route }) => {
    context.queryClient.ensureQueryData(getOptions(deps.search));
  },
});

function getOptions(settings: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [PROCESSES_QUERY_KEY, settings],
    queryFn: () => getDomains(settings),
    placeholderData: keepPreviousData,
  });
}

async function getDomains(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  const search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/domain", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting domains");
  }

  return response.data;
}

const PROCESSES_QUERY_KEY = "settings-domains";

export default function Domains() {
  const queryClient = useQueryClient();
  const urlSearch = useSearch({ from: "/_application/assets/domains/" });
  const [searchSettings, setSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>(urlSearch);
  const navigate = useNavigate();
  function updateSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useQuery(getOptions(searchSettings));

  return (
    <TableCard
      onUpdate={updateSearchSettings}
      query={query}
      searchable
      storeStateInUrl
      onClickNavigate={(row) => ({
        to: "/assets/domains/$domainId",
        params: { domainId: row.id },
      })}
      embedded
      headers={[
        { display: "Name", key: "name", sortable: true },
        {
          display: "Created at",
          key: "createdAt",
          sortable: true,
          format: (value, row) => {
            return moment(value).format("MMM Do YYYY");
          },
        },
      ]}
    >
      <CardHeader>
        <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
          <div className="flex flex-col gap-2">
            <CardTitle>Domains</CardTitle>
            <CardDescription>
              Domains that have been extracted from detections in your
              environment.
            </CardDescription>
          </div>
        </div>
      </CardHeader>
    </TableCard>
  );
}
