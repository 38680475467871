import {
  FileRiskType,
  FileRiskTypeConfig,
  getVerdictConfigByVerdict,
  type Verdict,
} from "./shared.types";

/*
 * READ THIS
 * When adding new categories, make sure to also add a matching subcategory, which is what we always default to if we don't match a more specific subcategory
 * Display values should be able to be combined in the sentence form "X alerted on a [verdict] [subcategory] [category]"
 */
export enum DetectionCategory {
  INVOCATION = "INVOCATION",
  EXECUTION = "EXECUTION",
  LOGIN = "LOGIN",
  PERSISTENCE = "PERSISTENCE",
  DATA_TRANSFER = "DATA_TRANSFER",
  NETWORK_CONNECTION = "NETWORK_CONNECTION",
  CREDENTIAL_EXPOSURE = "CREDENTIAL_EXPOSURE",
  DECEPTION = "DECEPTION",
  EMAIL = "EMAIL",
  POLICY = "POLICY",
  DIAGNOSTIC = "DIAGNOSTIC",
  NON_THREAT = "NON_THREAT",
  WARNING = "WARNING",
  UNKNOWN = "UNKNOWN",
}

export enum DetectionSubCategory {
  // Defaults
  UNKNOWN = "UNKNOWN",
  DIAGNOSTIC = "DIAGNOSTIC",
  WARNING = "WARNING",
  NON_THREAT = "NON_THREAT",

  // Invocation
  INVOCATION = "INVOCATION",

  // Execution
  EXECUTION = "EXECUTION",
  EXECUTION__MALWARE = "EXECUTION__MALWARE",
  EXECUTION__LATE_STAGE = "EXECUTION__LATE_STAGE",

  // Credential Exposure
  CREDENTIAL_EXPOSURE = "CREDENTIAL_EXPOSURE",
  CREDENTIAL_EXPOSURE__PUBLICLY_EXPOSED_CREDENTIALS = "CREDENTIAL_EXPOSURE__PUBLICLY_EXPOSED_CREDENTIALS",
  CREDENTIAL_EXPOSURE__PRIVATELY_EXPOSED_CREDENTIALS = "CREDENTIAL_EXPOSURE__PRIVATELY_EXPOSED_CREDENTIALS",

  // Email
  EMAIL = "EMAIL",
  EMAIL__REPORTED_PHISH = "EMAIL__REPORTED_PHISH",
  EMAIL__PHISH = "EMAIL__PHISH",

  // Policy
  POLICY = "POLICY",
  POLICY__MISCONFIGURATION = "POLICY__MISCONFIGURATION",

  // Login
  LOGIN = "LOGIN",
  LOGIN__BRUTE_FORCE = "LOGIN__BRUTE_FORCE",

  // Persistence
  PERSISTENCE = "PERSISTENCE",

  // Data transfer
  DATA_TRANSFER = "DATA_TRANSFER",
  DATA_TRANSFER__SHARE = "DATA_TRANSFER__SHARE",

  // Deception
  DECEPTION = "DECEPTION",

  // Network connection
  NETWORK_CONNECTION = "NETWORK_CONNECTION",
  NETWORK_CONNECTION__PHISH = "NETWORK_CONNECTION__PHISH",
  NETWORK_CONNECTION__INBOUND = "NETWORK_CONNECTION__INBOUND",
  NETWORK_CONNECTION__OUTBOUND = "NETWORK_CONNECTION__OUTBOUND",
  NETWORK_CONNECTION__NOISY = "NETWORK_CONNECTION__NOISY",
}

export const DetectionCategoryConfig: Record<
  DetectionCategory,
  {
    display: string;
    category: DetectionCategory;
  }
> = {
  [DetectionCategory.INVOCATION]: {
    display: "Invocation",
    category: DetectionCategory.INVOCATION,
  },
  [DetectionCategory.EXECUTION]: {
    display: "Execution",
    category: DetectionCategory.EXECUTION,
  },
  [DetectionCategory.LOGIN]: {
    display: "Login",
    category: DetectionCategory.LOGIN,
  },
  [DetectionCategory.PERSISTENCE]: {
    display: "Persistence",
    category: DetectionCategory.PERSISTENCE,
  },
  [DetectionCategory.NON_THREAT]: {
    display: "Informational Event",
    category: DetectionCategory.NON_THREAT,
  },
  [DetectionCategory.DATA_TRANSFER]: {
    display: "Data Transfer",
    category: DetectionCategory.DATA_TRANSFER,
  },
  [DetectionCategory.CREDENTIAL_EXPOSURE]: {
    display: "Credential Exposure",
    category: DetectionCategory.CREDENTIAL_EXPOSURE,
  },
  [DetectionCategory.NETWORK_CONNECTION]: {
    display: "Network Connection",
    category: DetectionCategory.NETWORK_CONNECTION,
  },
  [DetectionCategory.DECEPTION]: {
    display: "Deception",
    category: DetectionCategory.DECEPTION,
  },
  [DetectionCategory.EMAIL]: {
    display: "Email",
    category: DetectionCategory.EMAIL,
  },
  [DetectionCategory.POLICY]: {
    display: "Policy",
    category: DetectionCategory.POLICY,
  },
  [DetectionCategory.WARNING]: {
    display: "Warning",
    category: DetectionCategory.WARNING,
  },
  [DetectionCategory.UNKNOWN]: {
    display: "Unknown",
    category: DetectionCategory.UNKNOWN,
  },
  [DetectionCategory.DIAGNOSTIC]: {
    display: "Diagnostic",
    category: DetectionCategory.DIAGNOSTIC,
  },
};

export const DetectionSubCategoryConfig: Record<
  DetectionSubCategory,
  {
    display: string;
    // When we want to display subcat and cat, but want to override the display
    categoryDisplay?: string;
    category: DetectionCategory;
    subcategory: DetectionSubCategory;
  }
> = {
  // Invocation
  [DetectionSubCategory.INVOCATION]: {
    display: "Invocation",
    category: DetectionCategory.INVOCATION,
    subcategory: DetectionSubCategory.INVOCATION,
  },
  // Execution
  [DetectionSubCategory.EXECUTION]: {
    display: "Execution",
    category: DetectionCategory.EXECUTION,
    subcategory: DetectionSubCategory.EXECUTION,
  },
  [DetectionSubCategory.EXECUTION__LATE_STAGE]: {
    display: "Late Stage Tool",
    category: DetectionCategory.EXECUTION,
    subcategory: DetectionSubCategory.EXECUTION__LATE_STAGE,
  },
  [DetectionSubCategory.EXECUTION__MALWARE]: {
    display: "Malware",
    category: DetectionCategory.EXECUTION,
    subcategory: DetectionSubCategory.EXECUTION__MALWARE,
  },

  // Credential exposure
  [DetectionSubCategory.CREDENTIAL_EXPOSURE]: {
    display: "Credential Exposure",
    category: DetectionCategory.CREDENTIAL_EXPOSURE,
    subcategory: DetectionSubCategory.CREDENTIAL_EXPOSURE,
  },
  [DetectionSubCategory.CREDENTIAL_EXPOSURE__PUBLICLY_EXPOSED_CREDENTIALS]: {
    display: "Public",
    category: DetectionCategory.CREDENTIAL_EXPOSURE,
    subcategory: DetectionSubCategory.CREDENTIAL_EXPOSURE__PUBLICLY_EXPOSED_CREDENTIALS,
  },
  [DetectionSubCategory.CREDENTIAL_EXPOSURE__PRIVATELY_EXPOSED_CREDENTIALS]: {
    display: "Private",
    category: DetectionCategory.CREDENTIAL_EXPOSURE,
    subcategory: DetectionSubCategory.CREDENTIAL_EXPOSURE__PRIVATELY_EXPOSED_CREDENTIALS,
  },

  // Email
  [DetectionSubCategory.EMAIL]: {
    display: "Email",
    category: DetectionCategory.EMAIL,
    subcategory: DetectionSubCategory.EMAIL,
  },
  [DetectionSubCategory.EMAIL__REPORTED_PHISH]: {
    display: "Reported Phishing",
    category: DetectionCategory.EMAIL,
    subcategory: DetectionSubCategory.EMAIL__REPORTED_PHISH,
  },
  [DetectionSubCategory.EMAIL__PHISH]: {
    display: "Phishing",
    category: DetectionCategory.EMAIL,
    subcategory: DetectionSubCategory.EMAIL__PHISH,
  },

  // Policy
  [DetectionSubCategory.POLICY]: {
    display: "Policy",
    category: DetectionCategory.POLICY,
    subcategory: DetectionSubCategory.POLICY,
  },
  [DetectionSubCategory.POLICY__MISCONFIGURATION]: {
    display: "Misconfigured",
    category: DetectionCategory.POLICY,
    subcategory: DetectionSubCategory.POLICY__MISCONFIGURATION,
  },
  // Login
  [DetectionSubCategory.LOGIN]: {
    display: "Login",
    category: DetectionCategory.LOGIN,
    subcategory: DetectionSubCategory.LOGIN,
  },
  [DetectionSubCategory.LOGIN__BRUTE_FORCE]: {
    display: "Brute Force",
    categoryDisplay: "Brute Force Login Attempt",
    category: DetectionCategory.LOGIN,
    subcategory: DetectionSubCategory.LOGIN__BRUTE_FORCE,
  },

  // Persistence
  [DetectionSubCategory.PERSISTENCE]: {
    display: "Persistence",
    category: DetectionCategory.PERSISTENCE,
    subcategory: DetectionSubCategory.PERSISTENCE,
  },

  // Data transfer
  [DetectionSubCategory.DATA_TRANSFER]: {
    display: "Data Transfer",
    category: DetectionCategory.DATA_TRANSFER,
    subcategory: DetectionSubCategory.DATA_TRANSFER,
  },
  [DetectionSubCategory.DATA_TRANSFER__SHARE]: {
    display: "Data Share",
    category: DetectionCategory.DATA_TRANSFER,
    subcategory: DetectionSubCategory.DATA_TRANSFER__SHARE
  },

  // Deception
  [DetectionSubCategory.DECEPTION]: {
    display: "Deception",
    category: DetectionCategory.DECEPTION,
    subcategory: DetectionSubCategory.DECEPTION,
  },

  // Network connection
  [DetectionSubCategory.NETWORK_CONNECTION]: {
    display: "Network Connection",
    category: DetectionCategory.NETWORK_CONNECTION,
    subcategory: DetectionSubCategory.NETWORK_CONNECTION,
  },
  [DetectionSubCategory.NETWORK_CONNECTION__PHISH]: {
    display: "Phishing",
    category: DetectionCategory.NETWORK_CONNECTION,
    subcategory: DetectionSubCategory.NETWORK_CONNECTION__PHISH,
  },
  [DetectionSubCategory.NETWORK_CONNECTION__NOISY]: {
    display: "Noisy",
    category: DetectionCategory.NETWORK_CONNECTION,
    subcategory: DetectionSubCategory.NETWORK_CONNECTION__NOISY,
  },
  [DetectionSubCategory.NETWORK_CONNECTION__INBOUND]: {
    display: "Inbound",
    category: DetectionCategory.NETWORK_CONNECTION,
    subcategory: DetectionSubCategory.NETWORK_CONNECTION__INBOUND,
  },
  [DetectionSubCategory.NETWORK_CONNECTION__OUTBOUND]: {
    display: "Outbound",
    category: DetectionCategory.NETWORK_CONNECTION,
    subcategory: DetectionSubCategory.NETWORK_CONNECTION__OUTBOUND,
  },

  // Defaults
  [DetectionSubCategory.UNKNOWN]: {
    display: "Unknown",
    category: DetectionCategory.UNKNOWN,
    subcategory: DetectionSubCategory.UNKNOWN,
  },
  [DetectionSubCategory.WARNING]: {
    display: "Warning",
    category: DetectionCategory.WARNING,
    subcategory: DetectionSubCategory.WARNING,
  },
  [DetectionSubCategory.DIAGNOSTIC]: {
    display: "Diagnostic",
    category: DetectionCategory.DIAGNOSTIC,
    subcategory: DetectionSubCategory.DIAGNOSTIC,
  },
  [DetectionSubCategory.NON_THREAT]: {
    display: "Informational Event",
    category: DetectionCategory.NON_THREAT,
    subcategory: DetectionSubCategory.NON_THREAT,
  }
};
export function getSubCategoryTitle(
  subcategory: DetectionSubCategory,
  excludeCategory = false
) {
  const category = DetectionSubCategoryConfig[subcategory]?.category;
  const categoryDisplay = DetectionCategoryConfig[category]?.display;
  const subcategoryDisplayConfig = DetectionSubCategoryConfig[subcategory];
  const subcategoryDisplay = subcategoryDisplayConfig.display;

  if (subcategoryDisplayConfig.categoryDisplay != null) {
    return subcategoryDisplayConfig.categoryDisplay;
  }

  if (categoryDisplay === subcategoryDisplay || excludeCategory) {
    return subcategoryDisplay;
  }
  return `${subcategoryDisplay} ${categoryDisplay}`;
}

export function getSubcategoryTitleWithFileRiskType(
  subcategory: DetectionSubCategory,
  fileRiskType: FileRiskType
) {
  const subcategoryTitle = getSubCategoryTitle(subcategory);
  const riskType = FileRiskTypeConfig[fileRiskType]?.name;

  if (
    riskType == null ||
    subcategoryTitle.toLowerCase().includes(riskType.toLowerCase()) ||
    fileRiskType == FileRiskType.UNKNOWN
  ) {
    return subcategoryTitle;
  }
  return `${riskType} ${subcategoryTitle}`;
}

export function getSubcategoryTitleWithVerdict(
  verdict: Verdict,
  subcategory: DetectionSubCategory
) {
  const verdictDisplay = getVerdictConfigByVerdict(verdict).display;
  const subcategoryTitle = getSubCategoryTitle(subcategory);
  return `${verdictDisplay} ${subcategoryTitle}`;
}

export function getSubcategoryTitleWithVerdictAndFileRiskType(
  verdict: Verdict,
  subcategory: DetectionSubCategory,
  fileRiskType: FileRiskType
) {
  const verdictDisplay = getVerdictConfigByVerdict(verdict).display;
  const subcategoryWithRisk = getSubcategoryTitleWithFileRiskType(
    subcategory,
    fileRiskType
  );
  if (
    subcategoryWithRisk.toLowerCase().includes(verdictDisplay.toLowerCase())
  ) {
    return subcategoryWithRisk;
  }
  return `${verdictDisplay} ${subcategoryWithRisk}`;
}
