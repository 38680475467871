import { AppLayout } from "@/components/app-layout";
import ManageExclusion from "@/components/manage-exclusion";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { localDateTime } from "@/lib/time";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  createDetectionQuery,
  DETECTION_QUERY_FIELDS,
  getStatusConfigByStatus,
  QUERY_OPERATOR,
  ROLE,
} from "@wire/shared";
import { useMemo, useState } from "react";

export const Route = createFileRoute(
  "/_application/assets/user-agents/$userAgentId"
)({
  component: UserAgent,
  loader: async ({ params, context }) => {
    const response = await context.queryClient.ensureQueryData(
      getOptions(params.userAgentId)
    );
    context.title = response?.userAgent?.userAgent;
  },
});

async function getData(userAgentId: string) {
  const [userAgent] = await Promise.all([
    apiClient.GET("/user-agent/{id}", {
      params: { path: { id: userAgentId } },
    }),
  ]);
  if (userAgent.error != null) {
    throw new Error("Error getting user agent information");
  }
  return {
    userAgent: userAgent.data,
  };
}

export const ASSET_QUERY_KEY = "user-agent";
const getOptions = (userAgentId: string) =>
  queryOptions({
    queryKey: [ASSET_QUERY_KEY, userAgentId],
    queryFn: () => getData(userAgentId),
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  userAgentId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: {
        ...searchSettings,
        assetType: "USER_AGENT",
        assetId: userAgentId,
      },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}

async function searchUsers(
  searchSettings: components["schemas"]["DirectoryUserSearchDto"],
  userAgentId: string
) {
  const [users] = await Promise.all([
    apiClient.POST("/directory", {
      body: {
        ...searchSettings,
        userAgentId,
      },
    }),
  ]);
  if (users.error != null) {
    throw new Error("Error getting users information");
  }
  return users.data;
}

export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  userAgentId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, userAgentId],
    queryFn: () => searchCases(searchSettings, userAgentId),
    placeholderData: keepPreviousData,
  });

export const USERS_QUERY_KEY = "user-settings";
const getSearchUserOptions = (
  searchSettings: components["schemas"]["DirectoryUserSearchDto"],
  userAgentId: string
) =>
  queryOptions({
    queryKey: [USERS_QUERY_KEY, searchSettings, userAgentId],
    queryFn: () => searchUsers(searchSettings, userAgentId),
    placeholderData: keepPreviousData,
  });

function UserAgent() {
  const { userAgentId } = Route.useParams();
  const [createExclusionDialogOpen, setCreateExclusionDialogOpen] =
    useState(false);
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const [userSearchSettings, setUserSearchSettings] = useState<
    components["schemas"]["DirectoryUserSearchDto"]
  >({});
  const casesQuery = useQuery(
    getSearchCaseOptions(searchSettings, userAgentId)
  );
  const usersQuery = useQuery(
    getSearchUserOptions(userSearchSettings, userAgentId)
  );
  const {
    data: { userAgent },
  } = useSuspenseQuery(getOptions(userAgentId));
  const navigate = useNavigate();

  const defaultExclusionQuery = useMemo(() => {
    if (userAgent.userAgent != null) {
      return createDetectionQuery(
        DETECTION_QUERY_FIELDS.USER_AGENT,
        QUERY_OPERATOR.ALL_ARRAY_VALUES_EQUAL,
        `"${userAgent.userAgent}"`
      );
    }
    return "";
  }, []);

  return (
    <AppLayout>
      <ManageExclusion
        title="Create Exclusion"
        providedDetectionSid
        query={defaultExclusionQuery}
        name={userAgent.userAgent}
        detectionSid={casesQuery.data?.data[0]?.detectionSids[0]}
        description="Automatically close future detections that match this query"
        open={createExclusionDialogOpen}
        onClose={() => setCreateExclusionDialogOpen(false)}
      />
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 space-y-0 items-center mb-4 flex flex-col gap-4 lg:flex-row lg:justify-between">
            <div>
              <CardTitle>
                <code>{userAgent.userAgent}</code>
              </CardTitle>
              <CardDescription>User Agent</CardDescription>
            </div>
            <DropdownMenu requiredRole={ROLE.ANALYST}>
              <DropdownMenuTrigger asChild>
                <Button>Actions</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-w-64">
                <DropdownMenuItem
                  onClick={() => setCreateExclusionDialogOpen(true)}
                >
                  <div>
                    <h4 className="font-semibold">Create Exclusion</h4>
                    <p className="text-muted-foreground">
                      Automatically ignore detections that are associated with
                      this user agent
                    </p>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </CardHeader>
          <CardContent className="flex overflow-auto flex-col gap-2">
            <div>
              <h2 className="font-semibold">Created At</h2>
              <p className="text-sm">{localDateTime(userAgent.createdAt)}</p>
            </div>
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({
              to: "/cases/$caseId",
              params: { caseId: row.id },
            })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setSearchSettings({ ...searchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "name",
              sortable: true,
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "firstDetectionSourceDetectedAt",
              sortable: true,
              format(value) {
                return localDateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this user agent has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
        <TableCard
          onClickNavigate={(row) => ({
            to: "/assets/users/$userId",
            params: { userId: row.id },
          })}
          query={usersQuery}
          onUpdate={(settings) =>
            setUserSearchSettings({ ...userSearchSettings, ...settings } as any)
          }
          headers={[
            {
              display: "Name",
              key: "name",
              sortable: true,
            },
            {
              display: "Email",
              key: "email",
              sortable: true,
            },
            {
              display: "Title",
              key: "title",
              sortable: true,
            },
            {
              display: "Department",
              key: "department",
              sortable: true,
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Users</CardTitle>
            <CardDescription>
              Users that have been associated with this user agent
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </AppLayout>
  );
}
