import { AppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { pluralize } from "@/lib/utils";
import { AQLBatchStatus } from "@/routes/_application/admin/aql";
import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {
  createFileRoute,
  useNavigate,
  useRouteContext,
} from "@tanstack/react-router";
import moment from "moment";
import { useMemo, useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/admin/aql/$batchId")({
  component: AdminAQLBatch,
  loader: async ({ params, context }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(getSampleOptions(params.batchId)),
      context.queryClient.ensureQueryData(getBatchOptions(params.batchId)),
    ]);
  },
});

async function getSamples(
  batchId: string,
  searchSettings: components["schemas"]["SearchAQLSamplesDto"]
) {
  const result = await apiClient.POST("/aql/sample", {
    body: { batchId, ...searchSettings },
  });
  if (result.error != null) {
    throw new Error("Error getting samples");
  }
  return result.data;
}
const getBatchOptions = (batchId: string) =>
  queryOptions({
    queryKey: [BATCH_QUERY_KEY, batchId],
    queryFn: () => getBatch(batchId),
  });

const BATCH_QUERY_KEY = "admin-aql-batch";

async function getBatch(batchId: string) {
  const result = await apiClient.GET("/aql/batch/{id}", {
    params: { path: { id: batchId } },
  });
  if (result.error != null) {
    throw new Error("Error getting batch");
  }
  return result.data;
}
const getSampleOptions = (
  batchId: string,
  searchSettings: components["schemas"]["SearchAQLSamplesDto"] = {}
) =>
  queryOptions({
    queryKey: [SAMPLES_QUERY_KEY, batchId, searchSettings],
    queryFn: () => getSamples(batchId, searchSettings),
  });

const SAMPLES_QUERY_KEY = "admin-aql-samples";

function AdminAQLBatch() {
  const { batchId } = Route.useParams();
  const { teams } = useRouteContext({ from: "/_application" });
  const navigate = useNavigate();
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchAQLSamplesDto"]
  >({});
  const samplesQuery = useQuery(getSampleOptions(batchId, searchSettings));
  const { data: batch, refetch: refetchBatch } = useSuspenseQuery(
    getBatchOptions(batchId)
  );

  const batchHasDefects = useMemo(() => {
    return (
      batch.criticalDefects > 0 ||
      batch.majorDefects > 0 ||
      batch.minorDefects > 0
    );
  }, [batch]);

  const descriptionText = useMemo(() => {
    let text = "";
    if (batch.status == "IN_PROGRESS" || batch.status == "COMPLETED") {
      text = `Assessed against a sample size of ${batch.sampleSize} with an inspection level of ${batch.inspectionLevel}, a critical quality level of ${batch.criticalQuantityLevel}, a major quality level of ${batch.majorQuantityLevel}, and a minor quality level of ${batch.minorQuantityLevel}. `;
    }
    if (batch.status == "COMPLETED") {
      text = `Completed with ${batch.criticalDefects} critical ${pluralize(
        batch.criticalDefects,
        "defect"
      )}, ${batch.majorDefects} major ${pluralize(
        batch.majorDefects,
        "defect"
      )}, and ${batch.minorDefects} minor ${pluralize(
        batch.minorDefects,
        "defect"
      )}.`;
    }
    if (batch.status == "NOT_STARTED") {
      text =
        "This batch is not started, defect information will be available once it is in progress.";
    }
    return text;
  }, [batch]);

  async function updateBatch(dto: components["schemas"]["UpdateBatchDto"]) {
    const result = await apiClient.PATCH("/aql/batch/{id}", {
      params: { path: { id: batchId } },
      body: dto,
    });
    if (result.error != null) {
      toast.error(result.error.message);
      return;
    }

    toast.success("Batch updated");
    await refetchBatch();
  }

  return (
    <AppLayout>
      <Card className="bg-transparent border-none shadow-none">
        <CardHeader className="flex flex-col lg:items-center lg:flex-row lg:justify-between gap-4">
          <div>
            <CardTitle className="flex items-center flex-wrap">
              AQL Batch for {moment(batch.startDate).format("MMM Do, YYYY")} -{" "}
              {moment(batch.startDate)
                .add(batch.durationDays, "days")
                .format("MMM Do, YYYY")}
              <AQLBatchStatus
                className="text-nowrap ml-2"
                status={batch.status}
              />
            </CardTitle>
            <CardDescription>
              {descriptionText} This batch will automatically close once all
              samples have been reviewed.
            </CardDescription>
          </div>
        </CardHeader>
        <CardContent>
          <TableCard
            storeStateInUrl
            query={samplesQuery}
            onUpdate={(settings) =>
              setSearchSettings({ ...searchSettings, ...settings })
            }
            searchable
            onClick={(row) =>
              window.open(`/detections/${row.detectionId}`, "_blank")
            }
            headers={[
              {
                display: "Team",
                format(value, row) {
                  // Bit hacky, but yolo
                  return teams.find((t) => t.id == row.teamId)?.name;
                },
                key: "id",
              },
              {
                display: "Case ID",
                format(value, row) {
                  return row.detectionData?.sid;
                },
                key: "id",
              },
              {
                display: "Defect Level",
                key: "id",
                format(value, row) {
                  switch (row.defectLevel) {
                    case "NONE":
                      return <Badge variant="success">None</Badge>;
                    case "CRITICAL":
                      return <Badge variant="destructive">Critical</Badge>;
                    case "MAJOR":
                      return <Badge variant="warning">Major</Badge>;
                    case "MINOR":
                      return <Badge variant="secondary">Minor</Badge>;
                    default:
                      return <Badge variant="default">Needs review</Badge>;
                  }
                },
              },
              {
                display: "Manually Added",
                key: "manuallyAdded",
                format(value, row) {
                  return row.manuallyAdded;
                },
              },
            ]}
            rowActions={[
              {
                name: "View",
                onClick: (row) =>
                  navigate({
                    to: "/detections/$detectionId",
                    params: { detectionId: row.detectionId },
                  }),
              },
            ]}
          >
            <CardHeader className="flex flex-col lg:flex-row lg:justify-between gap-4 lg:items-center">
              <div>
                <CardTitle>AQL Samples</CardTitle>
                <CardDescription>All samples for the AQL batch</CardDescription>
              </div>
              <div>
                {batch.status == "COMPLETED" &&
                  batchHasDefects &&
                  !batch.fixesApplied && (
                    <Button onClick={() => updateBatch({ fixesApplied: true })}>
                      Mark Batch as Fixed
                    </Button>
                  )}
              </div>
            </CardHeader>
          </TableCard>
        </CardContent>
      </Card>
    </AppLayout>
  );
}
