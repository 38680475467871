import { AppLayout } from "@/components/app-layout";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { GLOBAL_TEAMS_QUERY_KEY, GLOBAL_TEAM_QUERY_KEY } from "@/lib/tanstack";
import { useDebounce, useIsMount } from "@/lib/utils";
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { showArticle } from "@intercom/messenger-js-sdk";
import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute, useRouteContext } from "@tanstack/react-router";
import { DOCS, ROLE, VerdictConfig } from "@wire/shared";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
export const Route = createFileRoute("/_application/settings/chat-ops")({
  component: ChatOpsSettings,
});

export default function ChatOpsSettings() {
  const isMount = useIsMount();
  const { team: defaultTeam } = useRouteContext({ from: "/_application" });
  const [team, setTeam] = useState(defaultTeam);
  const [welcomeMessage, setWelcomeMessage] = useState<string | undefined>(
    team.chatOpsWelcomeMessage
  );
  const [accountLockedMessage, setAccountLockedMessage] = useState<
    string | undefined
  >(team.chatOpsAccountLockedMessage);

  const queryClient = useQueryClient();
  const [chatOpsPerDay, setChatOpsPerDay] = useState<number | undefined>(
    team.maxChatOpsPerDay
  );
  const [chatOpsEscalationDelayMinutes, setChatOpsEscalationDelayMinutes] =
    useState<number | undefined>(team.chatOpsEscalationDelayMinutes);

  const {
    debounced: debouncedWelcomeMessage,
    override: skipDebounceWelcomeMessage,
  } = useDebounce(500, welcomeMessage);

  const {
    debounced: debouncedAccountLockedMessage,
    override: skipDebounceAccountLockedMessage,
  } = useDebounce(500, accountLockedMessage);
  const {
    debounced: debouncedMaxChatOps,
    override: skipDebounceChatOpsPerDay,
  } = useDebounce(500, chatOpsPerDay);
  const {
    debounced: debouncedChatOpsEscalationDelayMinutes,
    override: skipDebounceEscalationDelayMinutes,
  } = useDebounce(500, chatOpsEscalationDelayMinutes);

  const securityChatOpsEnabled = useMemo(() => {
    return team.securityChatOps;
  }, [team.securityChatOps]);

  const maxChatOpsEnabled = useMemo(() => {
    return team.maxChatOpsPerDay != null;
  }, [team.maxChatOpsPerDay]);

  const welcomeMessageEnabled = useMemo(() => {
    return (
      team.chatOpsWelcomeMessage != null &&
      team.chatOpsWelcomeMessage.length > 0
    );
  }, [team.chatOpsWelcomeMessage]);

  const accountLockedMessageEnabled = useMemo(() => {
    return (
      team.chatOpsAccountLockedMessage != null &&
      team.chatOpsAccountLockedMessage.length > 0
    );
  }, [team.chatOpsAccountLockedMessage]);

  async function updateWelcomeMessage(message: string | undefined) {
    await updateTeam(
      {
        chatOpsWelcomeMessage: message,
      },
      "Welcome message updated"
    );
  }

  async function updateAccountLockedMessage(message: string | undefined) {
    await updateTeam(
      { chatOpsAccountLockedMessage: message },
      "Account locked message updated"
    );
  }

  useEffect(() => {
    if (isMount) return;
    updateWelcomeMessage(debouncedWelcomeMessage);
  }, [debouncedWelcomeMessage]);

  useEffect(() => {
    if (isMount) return;
    updateAccountLockedMessage(debouncedAccountLockedMessage);
  }, [debouncedAccountLockedMessage]);

  async function updateRateLimit(val?: number) {
    await updateTeam(
      { maxChatOpsPerDay: val, disableMaxChatOps: val == null },
      val != null ? "Rate limit updated" : "Rate limit removed"
    );
  }

  useEffect(() => {
    if (isMount) return;
    updateRateLimit(debouncedMaxChatOps);
  }, [debouncedMaxChatOps]);

  async function updateEscalationDelay(val?: number) {
    if (val == null) {
      toast.error("Escalation delay must be present");
      return;
    }
    await updateTeam(
      { chatOpsEscalationDelayMinutes: val },
      "Escalation delay updated"
    );
  }

  useEffect(() => {
    if (isMount) return;
    setChatOpsEscalationDelayMinutes(team.chatOpsEscalationDelayMinutes);
  }, [team.chatOpsEscalationDelayMinutes]);

  useEffect(() => {
    if (isMount) return;
    setChatOpsPerDay(team.maxChatOpsPerDay);
  }, [team.maxChatOpsPerDay]);

  useEffect(() => {
    if (isMount) return;
    updateEscalationDelay(debouncedChatOpsEscalationDelayMinutes);
  }, [debouncedChatOpsEscalationDelayMinutes]);

  async function updateSecurityChatOps(val: boolean) {
    await updateTeam(
      { securityChatOps: val },
      val ? "Security team chat ops enabled" : "Security team chat ops disabled"
    );
  }

  async function updateTeam(
    dto: components["schemas"]["UpdateTeamDto"],
    success: string
  ) {
    const response = await apiClient.PATCH("/team", {
      body: dto,
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success(success);
    setTeam(response.data);
    await queryClient.invalidateQueries({ queryKey: [GLOBAL_TEAMS_QUERY_KEY] });
    await queryClient.invalidateQueries({ queryKey: [GLOBAL_TEAM_QUERY_KEY] });
  }

  async function applySecureDefaults() {
    await updateTeam(
      {
        chatOpsSecondFactor: true,
        securityChatOps: true,
        chatOpsEscalationDelayMinutes: 10,
        maxChatOpsPerDay: 10,
      },
      "Secure defaults applied"
    );
  }

  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="flex items-start flex-col lg:flex-row lg:justify-between lg:items-center">
            <div>
              <CardTitle>Chat Ops Settings</CardTitle>
              <CardDescription>
                Limits and configurations for chat operations
              </CardDescription>
            </div>

            <Button onClick={applySecureDefaults} variant="outline">
              Apply Secure Defaults
            </Button>
          </CardHeader>
          <CardContent className="flex gap-8 flex-col">
            {/* <div>
            <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  Security Team Chat Ops{" "}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CHAT_OPS)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.securityChatOps}
                    onCheckedChange={async (e) => {
                      await updateSecurityChatOps(e);
                    }}
                  />
                </div>
              </div> 

              <p className="text-xs text-muted-foreground mt-1">
                {!securityChatOpsEnabled && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{" "}
                  </span>
                )}{" "}
                Communicate with security team via chat integrations
              </p>
            </div>*/}
            <div>
              <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  VIP Chat Ops{" "}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CHAT_OPS)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.vipChatOps}
                    onCheckedChange={async (e) => {
                      await updateTeam({ vipChatOps: e }, "Settings updated");
                    }}
                  />
                </div>
              </div>

              <p className="text-xs text-muted-foreground mt-1">
                {!team.vipChatOps && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{" "}
                  </span>
                )}{" "}
                Include VIPs in Chat Ops
              </p>
            </div>
            <div>
              <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  Manager Chat Ops{" "}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CHAT_OPS)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.managerChatOps}
                    onCheckedChange={async (e) => {
                      await updateTeam(
                        { managerChatOps: e },
                        "Settings updated"
                      );
                    }}
                  />
                </div>
              </div>

              <p className="text-xs text-muted-foreground mt-1">
                {!team.managerChatOps && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{" "}
                  </span>
                )}{" "}
                If a user does not respond, escalate to their manager instead
              </p>
            </div>
            <div>
              <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  Multi-factor Verification{" "}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CHAT_OPS)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.chatOpsSecondFactor}
                    onCheckedChange={async (e) => {
                      await updateTeam(
                        { chatOpsSecondFactor: e },
                        "Settings updated"
                      );
                    }}
                  />
                </div>
              </div>

              <p className="text-xs text-muted-foreground mt-1">
                {!team.chatOpsSecondFactor && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{" "}
                  </span>
                )}{" "}
                Require SMS MFA or Supervisor approval
              </p>
            </div>
            <div>
              <Label className="flex items-center gap-1">
                Escalation Delay{" "}
                <InformationCircleIcon
                  onClick={() => showArticle(DOCS.CHAT_OPS)}
                  className="h-4 cursor-pointer w-4"
                />
              </Label>
              <Input
                value={chatOpsEscalationDelayMinutes ?? ""}
                required
                requiredRole={ROLE.ADMIN}
                onChange={(e) =>
                  setChatOpsEscalationDelayMinutes(parseInt(e.target.value))
                }
                className="mt-1"
                placeholder="10"
                type="number"
              />
              <p className="text-xs text-muted-foreground mt-1">
                When chat ops times out, what verdict to continue processing
                with
              </p>
            </div>
            <div>
              <Label className="flex items-center gap-1">
                Timeout Verdict{" "}
                <InformationCircleIcon
                  onClick={() => showArticle(DOCS.CHAT_OPS)}
                  className="h-4 cursor-pointer w-4"
                />
              </Label>
              <Select
                defaultValue={team.chatOpsTimeoutVerdict}
                onValueChange={(v) =>
                  updateTeam(
                    { chatOpsTimeoutVerdict: v as any },
                    "Settings updated"
                  )
                }
              >
                <SelectTrigger className="mt-1">
                  <SelectValue placeholder="Select verdict" />
                </SelectTrigger>
                <SelectContent>
                  {Object.values(VerdictConfig).map((v) => (
                    <SelectItem value={v.type}>{v.display}</SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <p className="text-xs text-muted-foreground mt-1">
                If there is no response received from chat ops by the timeout,
                what verdict to continue processing with
              </p>
            </div>
            <div>
              <Label className="flex items-center gap-1">
                Max Messages Per Day{" "}
                <InformationCircleIcon
                  onClick={() => showArticle(DOCS.CHAT_OPS_COMMUNICATION_PLAN)}
                  className="h-4 cursor-pointer w-4"
                />
              </Label>
              <div className="flex items-center mt-1 gap-2">
                <Input
                  value={chatOpsPerDay ?? ""}
                  onChange={(e) =>
                    setChatOpsPerDay(
                      e.target.value ? parseInt(e.target.value) : undefined
                    )
                  }
                  className=""
                  requiredRole={ROLE.ADMIN}
                  placeholder="10"
                  type="number"
                />
                <Button
                  onClick={() => {
                    setChatOpsPerDay(undefined);
                    skipDebounceChatOpsPerDay(undefined);
                  }}
                  variant="outline"
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </div>
              <p className="text-xs text-muted-foreground mt-1">
                {!maxChatOpsEnabled && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{" "}
                  </span>
                )}{" "}
                Maximum number of chat ops interactions Wirespeed can make
                within a 24 hour period
              </p>
            </div>
            <div>
              <Label className="flex items-center gap-1">
                Welcome Message{" "}
                <InformationCircleIcon
                  onClick={() => showArticle(DOCS.CHAT_OPS_COMMUNICATION_PLAN)}
                  className="h-4 cursor-pointer w-4"
                />
              </Label>
              <div className="flex  items-stretch mt-1 gap-2">
                <Textarea
                  value={welcomeMessage ?? ""}
                  rows={4}
                  requiredRole={ROLE.ADMIN}
                  placeholder="Introduce Wirespeed to your team..."
                  onChange={(e) => setWelcomeMessage(e.target.value)}
                />
                <div>
                  <Button
                    className="h-full"
                    onClick={() => {
                      setWelcomeMessage(undefined);
                      skipDebounceWelcomeMessage(undefined);
                    }}
                    variant="outline"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>

              <p className="text-xs text-muted-foreground mt-1">
                {!welcomeMessageEnabled && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{" "}
                  </span>
                )}{" "}
                Introductory message to be sent to users upon their first
                message from Wirespeed
              </p>
            </div>
            <div>
              <Label className="flex items-center gap-1">
                Account Locked Message{" "}
                <InformationCircleIcon
                  onClick={() => showArticle(DOCS.CHAT_OPS_COMMUNICATION_PLAN)}
                  className="h-4 cursor-pointer w-4"
                />
              </Label>
              <div className="flex  items-stretch mt-1 gap-2">
                <Textarea
                  value={accountLockedMessage ?? ""}
                  rows={4}
                  requiredRole={ROLE.ADMIN}
                  placeholder="Introduce Wirespeed to your team..."
                  onChange={(e) => setAccountLockedMessage(e.target.value)}
                />
              </div>

              <p className="text-xs text-muted-foreground mt-1">
                {!accountLockedMessageEnabled && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{" "}
                  </span>
                )}{" "}
                Message sent when a user's account is locked in response to an
                actionable detection
              </p>
            </div>
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
}
