import { BaseCustomEditor } from "@/components/flow/editor/base-custom-editor";
import { BaseFooter } from "@/components/flow/editor/base-footer";
import { NodeEditorFooter } from "@/components/flow/editor/utils/node-editor-footer";
import { NodeEditorProps } from "@/components/flow/flow.types";
import { getAllAncestorNodesInFlow } from "@/components/flow/flow.utils";
import { BaseCustomNodeDataProps } from "@/components/flow/node/base-custom-node";
import { CardContent } from "@/components/ui/card";
import { Combobox } from "@/components/ui/combo-box";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import {
  FlowNodeType,
  DetectionCategory,
  DetectionCategoryConfig,
  DetectionSubCategory,
  DetectionSubCategoryConfig,
  Verdict,
  VerdictConfig,
} from "@wire/shared";
import { useEdges, useNodes } from "@xyflow/react";
import { memo, useCallback, useMemo } from "react";

// TODO: Consolidate with `verdict-flow.ts` types
// If you add new types here, update `updateDataBoolean` so it properly resets them
export interface ActionNodeProps extends BaseCustomNodeDataProps {
  contain?: boolean;
  chatOps?: boolean;
  escalate?: boolean;
  close?: boolean;
  category?: DetectionCategory;
  subcategory?: DetectionSubCategory;
  verdict?: Verdict;
  containOnChatOpsFailure?: boolean;
}

function ActionNodeEditor(props: NodeEditorProps<ActionNodeProps>) {
  if (props.selectedNode == null) return null;
  const nodes = useNodes();
  const edges = useEdges();
  const updateDataBoolean = useCallback(
    (update: Partial<ActionNodeProps>) => {
      if (update.close) {
        update = {
          ...update,
          escalate: false,
          contain: false,
          chatOps: false,
        };
      } else if (Object.values(update).some((v) => v === true)) {
        update.close = false;
      }
      if (update.chatOps === false) {
        update.containOnChatOpsFailure = false;
      }
      props.updateData(update);
    },
    [props.updateData]
  );

  const hasOutgoingEdges = useMemo(() => {
    return edges.some((v) => v.source == props.selectedNode.id);
  }, [edges, props.selectedNode]);

  const noChatOps = useMemo(() => {
    const allNodesInFlow = getAllAncestorNodesInFlow(
      nodes,
      edges,
      props.selectedNode.id
    );
    return allNodesInFlow.some((n) => n.type == FlowNodeType.ACTION);
  }, [nodes, edges]);

  const subCategories = useMemo(() => {
    return Object.values(DetectionSubCategoryConfig)
      .sort((a, b) => a.category.localeCompare(b.category))
      .map((v) => ({
        label: (
          <div className="flex flex-col gap-1">
            <div>{v.display}</div>
            <div className="text-xs text-muted-foreground">
              {DetectionCategoryConfig[v.category].display}
            </div>
          </div>
        ),
        previewLabel: v.display,
        value: v.subcategory,
      }));
  }, []);

  const verdicts = useMemo(() => {
    return Object.values(VerdictConfig)
      .sort((a, b) => a.display.localeCompare(b.display))
      .map((v) => ({
        label: v.display,
        value: v.type,
      }));
  }, []);

  return (
    <>
      <CardContent>
        <BaseCustomEditor hideQuery {...props}></BaseCustomEditor>
        <div className="mt-2 flex flex-col gap-2">
          <div className="flex flex-col gap-1 mt-2">
            <Label className="text-xs">Verdict</Label>
            <Combobox
              key={props.selectedNode.id}
              placeholder="Select Verdict"
              defaultValue={props.selectedNode?.data.verdict as any}
              emptyMessage="No verdict"
              values={verdicts}
              onSelect={(v) => props.updateData({ verdict: v as any })}
            />
          </div>
          <div className="flex flex-col gap-1 mt-2">
            <Label className="text-xs">Subcategory</Label>
            <Combobox
              key={props.selectedNode.id}
              placeholder="Select Subcategory"
              defaultValue={props.selectedNode?.data.subcategory as any}
              emptyMessage="No subcategory"
              values={subCategories}
              onSelect={(v) =>
                props.updateData({
                  subcategory: v as any,
                  category:
                    DetectionSubCategoryConfig[v as DetectionSubCategory]
                      .category,
                })
              }
            />
          </div>
          <div className="flex flex-wrap gap-4">
            <div className="flex items-center mt-2 gap-1">
              <Label>Contain</Label>
              <Switch
                checked={props.selectedNode?.data.contain}
                onCheckedChange={(v) => updateDataBoolean({ contain: v })}
              />
            </div>
            {!noChatOps && (
              <div className="flex items-center mt-2 gap-1">
                <Label>Chat Ops</Label>
                <Switch
                  disabled={hasOutgoingEdges}
                  checked={props.selectedNode?.data.chatOps}
                  onCheckedChange={(v) => updateDataBoolean({ chatOps: v })}
                />
              </div>
            )}
            <div className="flex items-center mt-2 gap-1">
              <Label>Escalate</Label>
              <Switch
                checked={props.selectedNode?.data.escalate}
                onCheckedChange={(v) => updateDataBoolean({ escalate: v })}
              />
            </div>
            <div className="flex items-center mt-2 gap-1">
              <Label>Close</Label>
              <Switch
                checked={props.selectedNode?.data.close}
                onCheckedChange={(v) => updateDataBoolean({ close: v })}
              />
            </div>
            {props.selectedNode.data.chatOps && (
              <div className="flex items-center mt-2 gap-1">
                <Label>Contain on Chat Ops Failure</Label>
                <Switch
                  checked={
                    props.selectedNode?.data.containOnChatOpsFailure ?? false
                  }
                  onCheckedChange={(v) =>
                    props.updateData({ containOnChatOpsFailure: v })
                  }
                />
              </div>
            )}
          </div>
          {noChatOps && (
            <p className="text-xs text-muted-foreground mt-1">
              Chat ops has already been performed in this flow
            </p>
          )}
        </div>
      </CardContent>
      <BaseFooter onClose={props.onClose}>
        <NodeEditorFooter
          onNodeUpdate={props.updateNode}
          updateNodeById={props.updateNodeById}
          selectedNode={props.selectedNode}
        />
      </BaseFooter>
    </>
  );
}

const ActionNodeEditorMemo = memo(ActionNodeEditor);

export { ActionNodeEditorMemo as ActionNodeEditor };
