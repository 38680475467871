"use client";
import IntegrationIdentity from "@/components/integration/integration-identity";
import AddIntegration, {
  IntegrationLogo,
} from "@/components/settings/add-integration";
import { TableCard } from "@/components/table-card";
import { CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createFileRoute,
  useNavigate,
  useRouteContext,
} from "@tanstack/react-router";
import { ROLE } from "@wire/shared";
import { FetchResponse } from "openapi-fetch";
import { useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/settings/integrations/")({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getIntegrationQueryOptions({}));
  },
  component: IntegrationSettings,
});

async function searchIntegrations(dto: components["schemas"]["PaginationDto"]) {
  const response = await apiClient.POST("/integration", {
    body: { ...dto, includeDisabled: true },
  });
  if (response.error != null) {
    throw new Error("Error searching integration");
  }
  return response.data;
}

const getIntegrationQueryOptions = (
  searchSettings: components["schemas"]["PaginationDto"]
) => ({
  queryKey: [INTEGRATION_SETTINGS_QUERY, searchSettings],
  queryFn: () => searchIntegrations(searchSettings),
  placeholderData: keepPreviousData,
});

export const INTEGRATION_SETTINGS_QUERY = "settings-integration";

export default function IntegrationSettings() {
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["PaginationDto"]
  >({});
  const { team } = useRouteContext({ from: "/_application" });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const integrationQuery = useQuery(getIntegrationQueryOptions(searchSettings));

  async function toggleIntegration(
    integration: components["schemas"]["IntegrationV2"]
  ) {
    let response: FetchResponse<any, any, any>;
    if (integration.enabled) {
      response = await apiClient.PATCH("/integration/{id}", {
        params: { path: { id: integration.id } },
        body: { enabled: false },
      });
    } else {
      response = await apiClient.PATCH("/integration/{id}", {
        params: { path: { id: integration.id } },
        body: { enabled: true },
      });
    }
    if (response.error != null) {
      return toast.error("Error updating integration");
    }
    if (integration.enabled) {
      toast.warning("Integration disabled");
    } else {
      toast.success("Integration enabled");
    }
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_SETTINGS_QUERY],
    });
  }

  async function deleteIntegration(id: string) {
    const response = await apiClient.DELETE("/integration/{id}", {
      params: { path: { id: id } },
    });
    if (response.error != null) {
      toast.error("Error deleting integration");
    } else {
      toast.warning("Integration deleted");
    }
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_SETTINGS_QUERY],
    });
  }

  return (
    <TableCard
      embedded
      query={integrationQuery}
      onUpdate={setSearchSettings}
      onClick={(row) =>
        navigate({
          to: "/settings/integrations/$integrationId",
          params: { integrationId: row.id },
        })
      }
      rowActions={[
        {
          name: (row) => (row.enabled ? "Disable" : "Enable"),
          onClick: toggleIntegration,
          requiredRole: ROLE.ADMIN,
        },
        {
          name: "Delete",
          requiredRole: ROLE.ADMIN,
          confirm: true,
          confirmMessage: (row) =>
            `Are you sure you want to delete the this integration?`,
          onClick: (row) => deleteIntegration(row.id),
        },
      ]}
      headers={[
        {
          key: "id",
          display: "Name",
          format: (val, row) => {
            return (
              <div className="flex items-center space-x-2">
                <IntegrationLogo config={row.config} />
                <div className="flex flex-col">
                  <span>{row.config?.name}</span>
                </div>
              </div>
            );
          },
        },
        team.serviceProvider
          ? {
              key: "teamName",
              display: "Client",
            }
          : undefined,
        {
          key: "enabled",
          display: "Enabled",
        },
        {
          key: "identityFields",
          display: "Identity",
          format(value, row) {
            return (
              <IntegrationIdentity
                identityFields={row.identityFields}
                integrationPlatform={row.platform}
              />
            );
          },
        },
      ]}
    >
      <CardHeader>
        <div className="flex flex-col lg:items-center space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 lg:justify-between">
          <div className="flex flex-col flex-1 items-center lg:flex-row lg:justify-between">
            <CardTitle>Integrations</CardTitle>
            <AddIntegration />
          </div>
        </div>
      </CardHeader>
    </TableCard>
  );
}
