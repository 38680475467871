import { AppLayout } from "@/components/app-layout";
import FlowBuilder, {
  getFlowFromLocalStorage,
} from "@/components/flow/flow-builder";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Combobox } from "@/components/ui/combo-box";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { queryOptions, useQuery } from "@tanstack/react-query";
import {
  createFileRoute,
  useBlocker,
  useRouteContext,
} from "@tanstack/react-router";
import { FlowVerdictor, FlowVerdictorResult } from "@wire/shared";
import { ReactFlowProvider } from "@xyflow/react";
import copy from "copy-to-clipboard";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/admin/flow")({
  component: () => <Flow />,
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(
      getSearchCaseOptions({ size: 50 })
    );
  },
});

async function searchCases(dto: components["schemas"]["SearchCasesDto"]) {
  const response = await apiClient.POST("/admin/cases/search", {
    body: dto,
  });
  if (response.error != null) {
    throw new Error("Failed to search cases");
  }
  return response.data;
}

const SEARCH_CASES_QUERY_KEY = ["admin-case-search"];
function getSearchCaseOptions(dto: components["schemas"]["SearchCasesDto"]) {
  return queryOptions({
    queryKey: [SEARCH_CASES_QUERY_KEY, dto],
    queryFn: () => searchCases(dto),
    placeholderData: (v) => v,
  });
}

function Flow() {
  const [importedFlow, setImportedFlow] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useRouteContext({ from: "/_application" });
  const [caseSearchOptions, setCaseSearchOptions] = useState<
    components["schemas"]["SearchCasesDto"]
  >({ size: 50 });
  const [caseSearchQuery, setCaseSearchQuery] = useState("");
  const [selectedCase, setSelectedCase] = useState<
    components["schemas"]["Case"] | null
  >(null);
  const [simulationResult, setSimulationResult] = useState<
    FlowVerdictorResult | undefined
  >(undefined);
  const [saveStatus, setSaveStatus] = useState(false);
  const [simulationLogs, setSimulationLogs] = useState<string[]>([]);
  const [showSimulationLogs, setShowSimulationLogs] = useState(false);
  const [selectedDetectionId, setSelectedDetectionId] = useState<string | null>(
    null
  );
  const [selectedDetection, setSelectedDetection] = useState<
    components["schemas"]["Detection"] | undefined
  >(undefined);
  const [caseDetections, setCaseDetections] = useState<
    components["schemas"]["Detection"][]
  >([]);
  const [blur, setBlur] = useState(false);

  const { data: cases } = useQuery(getSearchCaseOptions(caseSearchOptions));
  if (cases == null) return;

  useBlocker({
    blockerFn: () =>
      window.confirm(
        "Are you sure you want to leave? You may lose unsaved changes."
      ),
    condition: !saveStatus,
  });

  useEffect(() => {
    if (selectedCase == null) {
      setSelectedDetectionId(null);
      return;
    }
    void (async () => {
      const response = await apiClient.GET("/admin/cases/{id}/detections", {
        params: { path: { id: selectedCase.id } },
      });
      if (response.error != null) {
        toast.error("Failed to get case detections");
        return;
      }
      setCaseDetections(response.data);
      setSelectedDetectionId(response.data[0].id);
    })();
  }, [selectedCase]);

  useEffect(() => {
    if (selectedDetectionId == null) {
      setSelectedDetection(undefined);
      return;
    }
    void (async () => {
      const response = await apiClient.GET("/admin/detection/{id}", {
        params: { path: { id: selectedDetectionId } },
      });
      if (response.error != null) {
        toast.error("Failed to get detection");
        return;
      }
      setSelectedDetection(response.data);
    })();
  }, [selectedDetectionId]);

  useEffect(() => {
    setCaseSearchOptions({
      ...caseSearchOptions,
      search: caseSearchQuery,
    });
  }, [caseSearchQuery]);

  function getFlowStateForExport() {
    let json = JSON.parse(localStorage.getItem("flow-state") ?? "{}");
    // Because we want the metadata at the top of the object
    json = {
      metadata: {
        exportedBy: user.email,
        exportedOn: moment().toISOString(),
      },
      ...json,
    };
    return JSON.stringify(json, null, 2);
  }

  function exportToClipboard() {
    copy(getFlowStateForExport());
    toast.success("Flow copied to clipboard");
  }

  function exportToFile() {
    const blob = new Blob([getFlowStateForExport()], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    link.download = `wirespeed-flow-${timestamp}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    toast.success("Flow exported to file");
  }

  async function importFromDB() {
    const response = await apiClient.GET("/admin/flow");
    if (response.error != null) {
      toast.error("Failed to get flow from DB");
      return;
    }
    setImportedFlow(JSON.stringify(response.data.flow, null, 2));

    toast.success("Flow imported from DB");
  }

  async function importFromClipboard() {
    try {
      const text = await navigator.clipboard.readText();
      const parsedFlow = JSON.parse(text);
      if (parsedFlow.nodes == null) {
        toast.error("Invalid flow data in clipboard");
        return;
      }
      setImportedFlow(undefined);
      setImportedFlow(text);
      toast.success("Flow imported from clipboard");
    } catch (error) {
      toast.error("Invalid flow data in clipboard");
    }
  }

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file == null) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const text = e.target?.result as string;
        JSON.parse(text);
        setImportedFlow(text);
        toast.success("Flow imported from file");
      } catch (error) {
        toast.error("Invalid flow data in file");
      }
    };
    reader.readAsText(file);
  }

  function backTest() {
    const flow = getFlowFromLocalStorage();
    if (flow == null) return;

    const flowVerdictor = new FlowVerdictor(selectedDetection, flow);
    const result = flowVerdictor.verdict();
    if (result.isErr()) {
      console.error(result.error);
      toast.error(result.error.message);
      return;
    }
    if (result.value.message != null) {
      toast.warning(result.value.message);
    }
    setSimulationResult(result.value);
    setSimulationLogs(result.value.logs);
  }
  const caseComboBoxValues = useMemo(() => {
    return cases.data.map((c) => ({
      label: (
        <div className="flex flex-col w-full overflow-hidden">
          <h2>{c.name ?? c.sid}</h2>
          <div className="flex gap-4  justify-between text-sm text-muted-foreground">
            <span className="flex-shrink-0">{c.sid}</span>
            <span className="truncate text-ellipsis">{c.teamName}</span>
          </div>
        </div>
      ),
      previewLabel: c.detectionSids.length > 1 ? "Complex Case" : c.name,
      value: c.id,
    }));
  }, [cases.data]);

  const comboBoxOnSelect = useCallback(
    (v: string) => {
      setSelectedCase(cases.data.find((c) => c.id === v)!);
    },
    [cases.data, setSelectedCase]
  );

  const importDetectionFromClipboard = useCallback(async () => {
    const text = await navigator.clipboard.readText();
    if (text == null) {
      toast.error("No detection data in clipboard");
      return;
    }
    try {
      setSelectedDetectionId(null);
      setSelectedCase(null);
      setSelectedDetection(JSON.parse(text));
      toast.success("Detection imported from clipboard");
    } catch (err: any) {
      toast.error(err.message);
    }
  }, []);

  return (
    <AppLayout>
      {showSimulationLogs && (
        <Dialog open={showSimulationLogs} onOpenChange={setShowSimulationLogs}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Simulation Logs</DialogTitle>
            </DialogHeader>
            <div>
              {simulationLogs.map((v) => (
                <p key={v}>{v}</p>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Card>
        <CardHeader className="flex flex-col lg:flex-row lg:justify-between gap-4">
          <div>
            <CardTitle>Verdict Flow</CardTitle>
            <CardDescription>
              UI visualizer and editor for verdict flows
            </CardDescription>
          </div>
          <div className="flex gap-2 items-center">
            {saveStatus && <p className="text-green-500 text-xs">Saved</p>}
            {!saveStatus && (
              <p className="text-destructive text-xs">Not Saved</p>
            )}
            {selectedCase != null && caseDetections.length > 1 && (
              <Combobox
                values={
                  caseDetections.map((c) => ({
                    label: (
                      <div className="flex flex-col gap-1">
                        <span>{c.title}</span>
                        <span className="text-muted-foreground text-xs">
                          {c.sid}
                        </span>
                      </div>
                    ),
                    previewLabel: c.sourceName ?? c.title,
                    value: c.id,
                  })) ?? []
                }
                emptyMessage="No detections found"
                popoverClassName="w-[300px]"
                value={selectedDetectionId}
                onSelect={setSelectedDetectionId}
                placeholder="Search detections"
              />
            )}
            <Combobox
              values={caseComboBoxValues}
              async
              emptyMessage="No cases found"
              popoverClassName="w-[300px]"
              onSelect={comboBoxOnSelect}
              placeholder="Search cases"
              onSearch={setCaseSearchQuery}
            />

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Actions</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  disabled={selectedDetection == null}
                  onClick={backTest}
                >
                  Back Test
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => setBlur(!blur)}>
                  {blur ? "Unblur" : "Blur"}
                </DropdownMenuItem>
                {simulationLogs.length > 0 && (
                  <>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      onClick={() => setShowSimulationLogs(true)}
                    >
                      View Simulation Logs
                    </DropdownMenuItem>
                  </>
                )}
                <DropdownMenuSeparator />
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>Import</DropdownMenuSubTrigger>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem onClick={importFromClipboard}>
                      Import Flow from Clipboard
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => fileInputRef.current?.click()}
                    >
                      Import Flow from File
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={importFromDB}>
                      Import Flow from DB
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={importDetectionFromClipboard}>
                      Import Detection from Clipboard
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuSub>
                <DropdownMenuSeparator />
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>Export</DropdownMenuSubTrigger>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem onClick={exportToClipboard}>
                      Export Flow to Clipboard
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={exportToFile}>
                      Export Flow to File
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuSub>
              </DropdownMenuContent>
            </DropdownMenu>

            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              accept="application/json"
              className="hidden"
            />
          </div>
        </CardHeader>
        <CardContent>
          <div className="h-[2000px] hidden lg:block max-h-[80vh] p-2 shadow-md w-full rounded border">
            <ReactFlowProvider>
              <FlowBuilder
                onSaveUpdate={setSaveStatus}
                simulationResult={simulationResult?.history}
                selectedDetection={selectedDetection}
                blur={blur}
                goToProblemNodeId={simulationResult?.problemNodeId}
                defaultFlow={importedFlow}
              />
            </ReactFlowProvider>
          </div>
          <div className="lg:hidden">
            <p className="text-destructive">
              Please use a larger screen to view this flow
            </p>
          </div>
        </CardContent>
      </Card>
    </AppLayout>
  );
}
