import { CardFooter } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { memo } from "react";
function BaseFooter(
  props: React.PropsWithChildren<{
    onClose: () => void;
    className?: string;
  }>
) {
  return (
    <CardFooter
      className={cn(
        "flex border-t pt-4 items-end flex-row justify-end gap-4",
        {
          "justify-between": props.children != null,
        },
        props.className
      )}
    >
      {props.children}
      <div className="flex items-center gap-2"></div>
    </CardFooter>
  );
}

const BaseFooterMemo = memo(BaseFooter);
export { BaseFooterMemo as BaseFooter };
