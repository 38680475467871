import { BaseCustomEdgeDataProps } from "@/components/flow/edge/base-custom-edge";
import { BaseCustomNodeDataProps } from "@/components/flow/node/base-custom-node";
import { fetchLatestDetection } from "@/components/manage-exclusion";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { components } from "@/lib/api.types";
import { cn } from "@/lib/utils";
import {
  evaluateQuery,
  FlowNodeType,
  getNestedValue,
  validateQuery,
} from "@wire/shared";
import { Edge, Node } from "@xyflow/react";
import { memo, useEffect, useMemo, useState } from "react";

function QueryPanel(props: {
  selectedNode?: Node<BaseCustomNodeDataProps>;
  selectedEdge?: Edge<BaseCustomEdgeDataProps>;
  show: boolean;
  onSelectedDetection?: (detection: components["schemas"]["Detection"]) => void;
  selectedDetection?: components["schemas"]["Detection"];
}) {
  const [data, setData] = useState<components["schemas"]["Detection"] | null>(
    props.selectedDetection ?? null
  );
  const [detectionSid, setDetectionSid] = useState(data?.sid ?? "");
  const [validQuery, setValidQuery] = useState(false);
  const [queryMatch, setQueryMatch] = useState(false);

  useEffect(() => {
    if (props.selectedDetection != null) {
      setDetectionSid(props.selectedDetection.sid);
      setData(props.selectedDetection);
    }
  }, [props.selectedDetection]);

  useEffect(() => {
    if (data != null && detectionSid == data.sid) return;
    const abortController = new AbortController();
    (async () => {
      const result = await fetchLatestDetection(detectionSid, abortController);
      if (result == null) return;
      setDetectionSid(result.dsid);
      setData(result.data);
      props.onSelectedDetection?.(result.data);
    })();

    return () => {
      abortController.abort();
    };
  }, [detectionSid]);

  const query = useMemo(() => {
    return props.selectedNode?.data.query ?? props.selectedEdge?.data?.query;
  }, [props.selectedNode?.data.query, props.selectedEdge?.data?.query]);

  const displayType: "selector" | "query" = useMemo(() => {
    if (props.selectedNode?.type == FlowNodeType.SWITCH) {
      return "selector";
    }
    return "query";
  }, [props.selectedNode, props.selectedEdge]);

  const validSelector = useMemo(() => {
    if (props.selectedNode?.type != FlowNodeType.SWITCH) return false;
    return (
      getNestedValue(data, props.selectedNode?.data.fieldQuery ?? "") != null
    );
  }, [props.selectedNode?.data.fieldQuery]);

  useEffect(() => {
    if (query == null || query.length == 0 || data == null) return;
    setQueryMatch(evaluateQuery(query, data));
  }, [query, data]);

  useEffect(() => {
    if (query == null || query.length == 0 || data == null) return;
    setValidQuery(validateQuery(query));
  }, [query, data]);

  return (
    <Card className={cn({ hidden: !props.show })}>
      <CardHeader className="flex flex-row justify-between gap-2">
        <CardTitle>Query</CardTitle>
        {displayType == "query" && (
          <div className="flex flex-row gap-2">
            <Badge variant={validQuery ? "success" : "destructive"}>
              {validQuery ? "Valid Query" : "Invalid Query"}
            </Badge>
            <Badge variant={queryMatch ? "success" : "warning"}>
              {queryMatch ? "Query Matches" : "Query Does Not Match"}
            </Badge>
          </div>
        )}
        {displayType == "selector" && (
          <Badge variant={validSelector ? "success" : "destructive"}>
            {validSelector ? "Valid Selector" : "Invalid Selector"}
          </Badge>
        )}
      </CardHeader>
      <CardContent>
        <div>
          <Label>Sample Detection</Label>
          <Input
            disabled={props.selectedDetection != null}
            onChange={(e) => setDetectionSid(e.target.value)}
            value={detectionSid}
          />
          <p className="text-xs text-muted-foreground">
            Your query will show true/false if it matches this detection
          </p>
        </div>
        <pre className="text-xs max-h-[400px] rounded-md mt-2 bg-muted p-4 overflow-auto">
          {JSON.stringify(data, null, 2)}
        </pre>
      </CardContent>
    </Card>
  );
}

const QueryPanelMemo = memo(QueryPanel);

export { QueryPanelMemo as QueryPanel };
