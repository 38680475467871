import { AppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { getTimezone } from "@/lib/time";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useRouteContext } from "@tanstack/react-router";
import { ROLE, userHasRole } from "@wire/shared";
import { useState } from "react";

async function searchSystemLog(dto: components["schemas"]["PaginationDto"]) {
  const response = await apiClient.POST("/team/log", {
    body: { ...dto },
  });
  if (response.error != null) {
    throw new Error("Error searching system log");
  }
  return response.data;
}

export function getSystemLogQueryOptions(
  searchSettings: components["schemas"]["PaginationDto"]
) {
  return {
    queryKey: [SYSTEM_LOG_SETTINGS_QUERY, searchSettings],
    queryFn: () => searchSystemLog(searchSettings),
    placeholderData: keepPreviousData,
  };
}

export const SYSTEM_LOG_SETTINGS_QUERY = "settings-system-log";

export default function SystemLogSettings() {
  const { user } = useRouteContext({ from: "/_application" });
  if (!userHasRole(user.role, ROLE.ADMIN)) {
    throw new Error("Unauthorized");
  }
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["PaginationDto"]
  >({});
  const systemLogQuery = useQuery(getSystemLogQueryOptions(searchSettings));

  return (
    <AppLayout>
      <TableCard
        query={systemLogQuery}
        onUpdate={setSearchSettings}
        searchable
        headers={[
          {
            key: "id",
            display: "Actor",
            format(value, row) {
              return row.userEmail ?? row.identifier ?? "-";
            },
          },
          { key: "message", display: "Action" },
          { key: "security", display: "Security Event", sortable: true },
          {
            key: "createdAt",
            sortable: true,
            dateTime: true,
            display: `Time (${getTimezone()})`,
          },
        ]}
      >
        <CardHeader>
          <div className="flex flex-col lg:items-center space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 lg:justify-between">
            <div>
              <CardTitle>System Log</CardTitle>
              <CardDescription>Events happening for your team</CardDescription>
            </div>
          </div>
        </CardHeader>
      </TableCard>
    </AppLayout>
  );
}
