import hljs from "highlight.js";
import "highlight.js/styles/github-dark.css";
import markdownit from "markdown-it";

const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
  highlight: function (str, lang) {
    if (lang != null && hljs.getLanguage(lang) != null) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch (__) {
        // ignore
      }
    }

    return ""; // use external default escaping
  },
});

const defaultRender =
  md.renderer.rules.link_open ??
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  // Add a new `target` attribute, or replace the value of the existing one.
  tokens[idx].attrSet("target", "_blank");

  // Pass the token to the default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

export default function markdownToHtml(markdown: string) {
  const result = md.render(markdown);
  return result;
}
