import { BaseCustomEditor } from "@/components/flow/editor/base-custom-editor";
import { BaseFooter } from "@/components/flow/editor/base-footer";
import { NodeEditorFooter } from "@/components/flow/editor/utils/node-editor-footer";
import { NodeEditorProps } from "@/components/flow/flow.types";
import { BaseCustomNodeDataProps } from "@/components/flow/node/base-custom-node";
import { CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { memo, useEffect, useState } from "react";

export interface SwitchNodeProps extends BaseCustomNodeDataProps {
  fieldQuery?: string;
}

function SwitchNodeEditor(props: NodeEditorProps<SwitchNodeProps>) {
  const [fieldQuery, setFieldQuery] = useState(
    props.selectedNode?.data.fieldQuery
  );

  useEffect(() => {
    props.updateData({ fieldQuery });
  }, [fieldQuery]);

  useEffect(() => {
    setFieldQuery(props.selectedNode?.data.fieldQuery ?? "");
  }, [props.selectedNode.id]);

  return (
    <>
      <CardContent>
        <BaseCustomEditor hideQuery {...props}></BaseCustomEditor>
        <div className="mt-2">
          <Label className="text-xs">Field</Label>
          <Input
            onChange={(e) => setFieldQuery(e.target.value)}
            value={fieldQuery}
            placeholder="id"
            className="font-mono"
          />
        </div>
      </CardContent>
      <BaseFooter onClose={props.onClose}>
        <NodeEditorFooter
          onNodeUpdate={props.updateNode}
          updateNodeById={props.updateNodeById}
          selectedNode={props.selectedNode}
        />
      </BaseFooter>
    </>
  );
}

const SwitchNodeEditorMemo = memo(SwitchNodeEditor);

export { SwitchNodeEditorMemo as SwitchNodeEditor };
