import { AppLayout } from "@/components/app-layout";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, Link, ReactNode } from "@tanstack/react-router";
import { QueueStatus } from "@wire/shared";
import { useMemo } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/admin/queues")({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getOptions());
  },
  component: Queues,
});

async function getSettings() {
  const queues = await apiClient.GET("/admin/queue");
  if (queues.error != null) {
    throw new Error("Error getting settings information");
  }
  return queues.data;
}

const ADMIN_SETTINGS_QUERY_KEY = "admin-queue-settings";
const getOptions = () =>
  queryOptions({
    queryKey: [ADMIN_SETTINGS_QUERY_KEY],
    queryFn: getSettings,
  });

type QueueGroups = {
  [group: string]: components["schemas"]["QueueStatus"][];
};

export default function Queues() {
  const queryClient = useQueryClient();
  const { data: queues } = useSuspenseQuery(getOptions());
  const groups = useMemo(() => {
    const out = queues.reduce((acc, val) => {
      if (acc[val.group] == null) acc[val.group] = [];
      acc[val.group].push(val);
      return acc;
    }, {} as QueueGroups);
    return Object.values(out);
  }, [queues]);

  async function pauseQueues() {
    const response = await apiClient.PATCH("/admin/queue/pause");
    toast.success("Paused all queues");
    await queryClient.invalidateQueries({
      queryKey: [ADMIN_SETTINGS_QUERY_KEY],
    });
  }

  async function resumeQueues() {
    const response = await apiClient.PATCH("/admin/queue/resume");
    toast.success("Resumed all queues");
    await queryClient.invalidateQueries({
      queryKey: [ADMIN_SETTINGS_QUERY_KEY],
    });
  }

  return (
    <AppLayout>
      <div className="flex flex-col space-y-4">
        <div className="flex gap-2 justify-end">
          {queues.some((v) => !v.paused) && (
            <Button onClick={() => pauseQueues()} variant="outline">
              Pause All
            </Button>
          )}
          {queues.some((v) => v.paused) && (
            <Button onClick={() => resumeQueues()} variant="outline">
              Resume All
            </Button>
          )}
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
          {groups.map((group) => {
            return (
              <Card key={group[0].group}>
                <CardHeader>
                  <CardTitle>{group[0].group}</CardTitle>
                  <CardDescription>{group[0].description}</CardDescription>
                </CardHeader>
                <CardContent>
                  <ul className="grid mt-2 grid-cols-1 gap-4">
                    {group
                      .sort((a, b) => (a.order > b.order ? 1 : -1))
                      .map((v) => (
                        <Link
                          key={v.name}
                          to="/admin/$queueName"
                          params={{ queueName: v.name }}
                        >
                          <li className="p-4 flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between hover:bg-muted border rounded-md">
                            <h2 className="text-lg font-semibold">
                              {v.display}
                            </h2>
                            <QueueStatusBadges
                              display={v.display}
                              statuses={Object.entries(v.statuses)}
                              paused={v.paused}
                            />
                          </li>
                        </Link>
                      ))}
                  </ul>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </div>
    </AppLayout>
  );
}

export function QueueStatusBadges(props: {
  statuses: [name: string, count: number][];
  display: string;
  paused: boolean;
}) {
  const validStatuses = props.statuses.filter((v) => v[1] > 0);
  const badges: ReactNode[] = [];
  if (props.paused) {
    badges.push(
      <li key={`paused-${props.display}`}>
        <Badge variant="warning">Paused</Badge>
      </li>
    );
  }
  if (!validStatuses.length) {
    badges.push(
      <li key={`none-${props.display}`}>
        <Badge variant="outline">None</Badge>
      </li>
    );
  } else {
    badges.push(
      ...validStatuses
        .sort(([a], [b]) =>
          getQueueStatusSort(a as QueueStatus) >
          getQueueStatusSort(b as QueueStatus)
            ? 1
            : -1
        )
        .map(([name, count]: any) => (
          <li key={name}>
            <Badge variant={getQueueStatusVariant(name)}>
              {name} {count}
            </Badge>
          </li>
        ))
    );
  }
  return <ul className="flex flex-wrap gap-2">{badges}</ul>;
}

export function getQueueStatusVariant(status: QueueStatus) {
  switch (status) {
    case QueueStatus.active:
    case QueueStatus.paused:
    case QueueStatus.prioritized:
    case QueueStatus.repeat:
    case QueueStatus.waiting:
    case QueueStatus.delayed:
    case QueueStatus.waiting_children:
      return "outline";
    case QueueStatus.failed:
      return "destructive";
    case QueueStatus.completed:
      return "success";
  }
}

export function getQueueStatusSort(status: QueueStatus) {
  switch (status) {
    case QueueStatus.active:
    case QueueStatus.paused:
    case QueueStatus.prioritized:
    case QueueStatus.repeat:
    case QueueStatus.waiting:
    case QueueStatus.delayed:
    case QueueStatus.waiting_children:
      return 3;
    case QueueStatus.failed:
      return 2;
    case QueueStatus.completed:
      return 1;
  }
}
