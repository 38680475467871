import {
  Cases,
  CaseSearchStatus,
  getCasesOptions,
} from "@/components/cases/cases";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_application/cases/")({
  loaderDeps: ({ search }) => {
    return {
      filter: search.filter as CaseSearchStatus,
      page: search.page,
      search: search.search,
      orderBy: search.orderBy,
      orderDir: search.orderDir,
    };
  },
  loader: async ({ context, deps }) => {
    await context.queryClient.ensureQueryData(
      getCasesOptions({
        statuses: deps.filter != null ? [deps.filter] : [],
        page: deps.page,
        search: deps.search,
        orderBy: deps.orderBy,
        orderDir: deps.orderDir,
      })
    );
  },
  component: () => {
    const search = Route.useSearch();
    return (
      <Cases
        search={{ ...search, filter: search.filter as CaseSearchStatus }}
      />
    );
  },
});
