import AWS from "@/components/settings/integrations/aws";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { cn } from "@/lib/utils";
import { INTEGRATION_SETTINGS_QUERY } from "@/routes/_application/settings/integrations/";
import { showArticle } from "@intercom/messenger-js-sdk";
import { PlusIcon } from "@radix-ui/react-icons";
import { useQueryClient } from "@tanstack/react-query";
import { useRouteContext } from "@tanstack/react-router";
import { DOCS, ROLE } from "@wire/shared";
import copy from "copy-to-clipboard";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";

export default function AddIntegration(props: {
  integrationSlug?: string;
  onboarding?: boolean;
  hideTrigger?: boolean;
  open?: boolean;
  integrationToUpdate?: components["schemas"]["IntegrationV2"];
  update?: boolean;
  onChange?: (open: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const { team, user, integrationMetadata } = useRouteContext({
    from: props.onboarding ? "/_onboarding" : "/_application",
  });
  const [open, setOpen] = useState(props.open ?? false);
  const [integrationSlug, setIntegrationSlug] = useState<string | undefined>(
    props.integrationSlug
  );

  useEffect(() => {
    setIntegrationSlug(props.integrationSlug);
  }, [props.integrationSlug]);

  useEffect(() => {
    props.onChange?.(open);

    if (!open) {
      setIntegrationSlug(props.integrationSlug ?? undefined);
    }
  }, [open]);

  useEffect(() => {
    if (props.open != null) {
      setOpen(props.open);
    }
  }, [props.open]);

  async function complete() {
    setOpen(false);
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_SETTINGS_QUERY],
    });
  }

  const integrations = useMemo(() => {
    return integrationMetadata
      .sort((a, b) => a.slug.localeCompare(b.slug))
      .map((metadata) => (
        <SelectItem value={metadata.slug} key={metadata.slug}>
          <div className="flex gap-2 flex-row items-center">
            <IntegrationLogo config={metadata} />
            <span className="flex-shrink-0">{metadata.name}</span>
            {metadata.beta && <Badge className="text-xs">Beta</Badge>}
          </div>
        </SelectItem>
      ));
  }, [integrationMetadata]);

  return (
    <Dialog requiredRole={ROLE.ADMIN} open={open} onOpenChange={setOpen}>
      {!props.hideTrigger && (
        <DialogTrigger asChild>
          {team.serviceProvider ? (
            <HoverCard openDelay={0} closeDelay={50}>
              <HoverCardTrigger>
                <Button variant={props.update ? "warning" : "default"} disabled>
                  <PlusIcon className="h-5 w-5 mr-2" />
                  {props.update ? "Update integration" : "Add new integration"}
                </Button>
              </HoverCardTrigger>
              <HoverCardContent>
                <p>
                  You can't add integrations to a service provider account,
                  please add them to the respective client accounts.
                </p>
              </HoverCardContent>
            </HoverCard>
          ) : (
            <Button variant={props.update ? "warning" : "default"}>
              {!props.update && <PlusIcon className="h-5 w-5 mr-2" />}
              {props.update ? "Update integration" : "Add new integration"}
            </Button>
          )}
        </DialogTrigger>
      )}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {props.update ? "Update integration" : "Add new integration"}
          </DialogTitle>
          <DialogDescription>
            {props.update
              ? "Update your integration"
              : "Integrate with your security platforms to automate detection and response workflows."}
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="space-y-4">
            <div>
              <Label>Type</Label>
              <Select
                disabled={props.update}
                value={integrationSlug}
                onValueChange={(e) => {
                  setIntegrationSlug(e);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select an integration" />
                </SelectTrigger>
                <SelectContent>{integrations}</SelectContent>
              </Select>
            </div>
            {integrationSlug && (
              <IntegrationTypeComponent
                onboarding={props.onboarding}
                onComplete={complete}
                update={props.update}
                integrationToUpdate={props.integrationToUpdate}
                platform={integrationSlug}
                metadata={
                  integrationMetadata.find((v) => v.slug == integrationSlug)!
                }
              />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function IntegrationTypeComponent(props: {
  platform: string;
  metadata: components["schemas"]["IntegrationMetadataConfigV2"];
  onComplete: () => void;
  update?: boolean;
  onboarding?: boolean;
  integrationToUpdate?: components["schemas"]["IntegrationV2"];
}) {
  if (props.metadata.slug == "aws") {
    return <AWS onComplete={props.onComplete} />;
  }
  if (
    props.metadata.authType == "oauth2" &&
    props.metadata.customFields?.filter((v) => v.source == "ui").length == 0
  ) {
    return (
      <Oauth2Integration
        metadata={props.metadata}
        onComplete={props.onComplete}
        update={props.update}
        integrationToUpdate={props.integrationToUpdate}
      />
    );
  } else if (props.metadata.authType == "api_token") {
    return (
      <ApiKeyIntegration
        metadata={props.metadata}
        onComplete={props.onComplete}
        update={props.update}
        integrationToUpdate={props.integrationToUpdate}
      />
    );
  } else if (props.metadata.authType == "basic") {
    return (
      <BasicIntegration
        metadata={props.metadata}
        onComplete={props.onComplete}
        update={props.update}
        integrationToUpdate={props.integrationToUpdate}
      />
    );
  } else {
    return (
      <OtherIntegration
        metadata={props.metadata}
        onComplete={props.onComplete}
        update={props.update}
        integrationToUpdate={props.integrationToUpdate}
      />
    );
  }
}

export function IntegrationLogo(props: {
  config?: components["schemas"]["IntegrationMetadataConfigV2"];
  className?: string;
}) {
  if (props.config == null) {
    return null;
  }
  return props.config.logo != null ? (
    <img
      src={props.config.logo}
      className={cn("max-h-4 max-w-4 h-auto w-auto", props.className)}
    />
  ) : (
    <div>
      <img
        src={props.config.logoLight}
        className={cn(
          "dark:hidden max-h-4 max-w-4 h-auto w-auto",
          props.className
        )}
      />
      <img
        src={props.config.logoDark}
        className={cn(
          "hidden dark:block max-h-4 max-w-4 h-auto w-auto",
          props.className
        )}
      />
    </div>
  );
}

export function Oauth2Integration(props: {
  metadata: components["schemas"]["IntegrationMetadataConfigV2"];
  onComplete: () => void;
  update?: boolean;
  integrationToUpdate?: components["schemas"]["IntegrationV2"];
}) {
  const [formData, setFormData] = useState<Record<string, string>>(
    getDefaultFormData(props.metadata, props.integrationToUpdate)
  );
  const [fetching, setFetching] = useState(false);

  async function fetchURL() {
    setFetching(true);
    const response = await apiClient.POST("/integration/oauth/url", {
      params: {
        query: {
          integration: props.metadata.slug,
        },
      },
      body: {
        fields: Object.entries(formData).map(([name, value]) => ({
          name,
          value,
        })),
      },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    setFetching(false);
    return response.data.url;
  }

  async function integrate() {
    const url = await fetchURL();
    if (!url) return;
    window.open(url, "_blank");
    props.onComplete?.();
  }

  async function copyLink() {
    const url = await fetchURL();
    if (!url) return;
    copy(url);
    toast.success("URL copied to clipboard", {
      action: {
        label: "More info",
        onClick: () => showArticle(DOCS.FAQ),
      },
    });
    props.onComplete?.();
  }

  return (
    <>
      <CustomFormFields
        metadata={props.metadata}
        formData={formData}
        update={props.update}
        setFormData={setFormData}
      />
      <div className={cn("space-y-2")}>
        <div className="flex flex-row space-x-2">
          <Button onClick={integrate} disabled={fetching}>
            {fetching ? "Integrating..." : "Integrate"}
          </Button>
          <Button onClick={copyLink} variant="outline" disabled={fetching}>
            Copy Link for Admin
          </Button>
          <DocsLink metadata={props.metadata} />
        </div>
      </div>
    </>
  );
}

export function DocsLink(props: {
  metadata: components["schemas"]["IntegrationMetadataConfigV2"];
}) {
  if (props.metadata.docsId == null && props.metadata.docsUrl == null) {
    return null;
  }
  if (props.metadata.docsUrl != null) {
    return (
      <Button
        variant="outline"
        onClick={(e) => {
          e.preventDefault();
          window.open(props.metadata.docsUrl, "_blank");
        }}
      >
        View Instructions
      </Button>
    );
  }
  return (
    <Button
      variant="outline"
      onClick={(e) => {
        e.preventDefault();
        showArticle(props.metadata.docsId);
      }}
    >
      View Instructions
    </Button>
  );
}

export function OtherIntegration(props: {
  metadata: components["schemas"]["IntegrationMetadataConfigV2"];
  integrationToUpdate?: components["schemas"]["IntegrationV2"];
  onComplete: () => void;
  update?: boolean;
}) {
  const [formData, setFormData] = useState<Record<string, string>>(
    getDefaultFormData(props.metadata, props.integrationToUpdate)
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await apiClient.PUT("/integration/other", {
        params: {
          query: {
            integration: props.metadata.slug,
          },
        },
        body: {
          fields: Object.entries(formData).map(([name, value]) => ({
            name,
            value,
          })),
        },
      });

      if (response.error != null) {
        toast.error(response.error.message);
        return;
      }

      props.onComplete();
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <CustomFormFields
        metadata={props.metadata}
        formData={formData}
        update={props.update}
        setFormData={setFormData}
      />

      <div className="flex flex-row gap-2">
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Integrating..." : "Integrate"}
        </Button>
        <DocsLink metadata={props.metadata} />
      </div>
    </form>
  );
}

export function BasicIntegration(props: {
  metadata: components["schemas"]["IntegrationMetadataConfigV2"];
  integrationToUpdate?: components["schemas"]["IntegrationV2"];
  onComplete: () => void;
  update?: boolean;
}) {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [formData, setFormData] = useState<Record<string, string>>(
    getDefaultFormData(props.metadata, props.integrationToUpdate)
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await apiClient.PUT("/integration/basic", {
        params: {
          query: {
            integration: props.metadata.slug,
          },
        },
        body: {
          username,
          password,
          fields: Object.entries(formData).map(([name, value]) => ({
            name,
            value,
          })),
        },
      });

      if (response.error != null) {
        toast.error(response.error.message);
        return;
      }

      props.onComplete();
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {!props.metadata.internalCreds && (
        <>
          <div>
            <Label>
              Username <span className="text-destructive ml-1">*</span>
            </Label>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <Label>
              Password <span className="text-destructive ml-1">*</span>
            </Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </>
      )}
      <CustomFormFields
        metadata={props.metadata}
        formData={formData}
        update={props.update}
        setFormData={setFormData}
      />

      <div className="flex flex-row gap-2">
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Integrating..." : "Integrate"}
        </Button>
        <DocsLink metadata={props.metadata} />
      </div>
    </form>
  );
}

function CustomFormFields(props: {
  metadata: components["schemas"]["IntegrationMetadataConfigV2"];
  formData: Record<string, string>;
  setFormData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  update?: boolean;
}) {
  return props.metadata.customFields
    .filter((v) => v.source == "ui" || v.source == "ui-and-oauth.state")
    .map((field) => (
      <CustomFormField
        key={field.slug}
        field={field}
        disabled={props.update}
        formData={props.formData}
        setFormData={props.setFormData}
      />
    ));
}

function getDefaultFormData(
  metadata: components["schemas"]["IntegrationMetadataConfigV2"],
  integrationToUpdate?: components["schemas"]["IntegrationV2"]
) {
  return metadata.customFields.reduce(
    (acc, field) => {
      if (field.source != "ui" && field.source != "ui-and-oauth.state") {
        return acc;
      }
      if (integrationToUpdate?.identityFields[field.slug] != null) {
        acc[field.slug] = integrationToUpdate.identityFields[field.slug];
      } else if (field.type == "boolean") {
        acc[field.slug] = "false";
      }
      return acc;
    },
    {} as Record<string, string>
  );
}

function CustomFormField(props: {
  field: components["schemas"]["IntegrationMetadataConfigCustomFieldV2"];
  formData: Record<string, string>;
  disabled?: boolean;
  setFormData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}) {
  if (props.field.type == "boolean") {
    return (
      <div key={props.field.slug} className="flex flex-col gap-1">
        <div className="flex flex-row gap-2 items-center">
          <Checkbox
            id={props.field.slug}
            disabled={props.disabled}
            checked={props.formData[props.field.slug] == "true"}
            onCheckedChange={(checked) =>
              props.setFormData((prev) => ({
                ...prev,
                [props.field.slug]: checked ? "true" : "false",
              }))
            }
          />
          <Label htmlFor={props.field.slug}>{props.field.display}</Label>
        </div>
        {props.field.description != null && (
          <p className="text-xs text-muted-foreground">
            {props.field.description}
          </p>
        )}
      </div>
    );
  }
  return (
    <div key={props.field.slug} className="space-y-2">
      <div>
        <Label htmlFor={props.field.slug}>
          {props.field.display}
          {props.field.required && (
            <span className="text-destructive ml-1">*</span>
          )}
        </Label>

        {props.field.description != null && (
          <p className="text-sm text-muted-foreground">
            {props.field.description}
          </p>
        )}
      </div>

      <input
        id={props.field.slug}
        disabled={props.disabled}
        type={props.field.type === "password" ? "password" : "text"}
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        value={props.formData[props.field.slug] ?? ""}
        onChange={(e) =>
          props.setFormData((prev) => ({
            ...prev,
            [props.field.slug]: e.target.value,
          }))
        }
        required={props.field.required}
        pattern={props.field.validationPattern}
        minLength={props.field.validationMinLength}
        maxLength={props.field.validationMaxLength}
      />
    </div>
  );
}

export function ApiKeyIntegration(props: {
  metadata: components["schemas"]["IntegrationMetadataConfigV2"];
  integrationToUpdate?: components["schemas"]["IntegrationV2"];
  onComplete: () => void;
  update?: boolean;
}) {
  const [formData, setFormData] = useState<Record<string, string>>(
    getDefaultFormData(props.metadata, props.integrationToUpdate)
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await apiClient.PUT("/integration/api-key", {
        params: {
          query: {
            integration: props.metadata.slug,
          },
        },
        body: {
          apiKey: formData.apiKey,
          fields: Object.entries(formData).map(([name, value]) => ({
            name,
            value,
          })),
        },
      });

      if (response.error != null) {
        toast.error(response.error.message);
        return;
      }

      props.onComplete();
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {!props.metadata.internalCreds && (
        <div className="flex flex-col gap-1">
          <Label>
            API Key <span className="text-destructive ml-1">*</span>
          </Label>
          <p className="text-xs text-muted-foreground">
            API Key to authenticate with {props.metadata.name}
          </p>
          <Input
            type="password"
            value={formData.apiKey}
            onChange={(e) =>
              setFormData({ ...formData, apiKey: e.target.value })
            }
          />
        </div>
      )}
      <CustomFormFields
        metadata={props.metadata}
        formData={formData}
        update={props.update}
        setFormData={setFormData}
      />

      <div className="flex flex-row gap-2">
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Integrating..." : "Integrate"}
        </Button>
        <DocsLink metadata={props.metadata} />
      </div>
    </form>
  );
}
