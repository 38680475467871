import BaseCustomEdge, {
  BaseCustomEdgeComponentProps,
  BaseCustomEdgeDataProps,
} from "@/components/flow/edge/base-custom-edge";
import { Edge, type EdgeProps } from "@xyflow/react";
import { memo, useMemo } from "react";

export interface JumpEdgeProps extends BaseCustomEdgeDataProps {}

export interface JumpEdgeCompProps extends BaseCustomEdgeComponentProps {}

function JumpEdge(props: EdgeProps<Edge<JumpEdgeProps>> & JumpEdgeCompProps) {
  const hidden = useMemo(() => {
    if (props.data?.simulating) return false;
    return props.data?.hidden ?? false;
  }, [props.data?.hidden, props.data?.simulating]);
  return (
    <BaseCustomEdge
      {...props}
      data={{ ...props.data, hidden }}
    ></BaseCustomEdge>
  );
}

const JumpEdgeMemo = memo(JumpEdge);

export { JumpEdgeMemo as JumpEdge };
