import { MatchEdgeProps } from "@/components/flow/editor/edge/match-edge-editor";
import { SwitchNodeProps } from "@/components/flow/editor/node/switch-node-editor";
import {
  BaseCustomNode,
  BaseCustomNodeComponentProps,
} from "@/components/flow/node/base-custom-node";
import { FlowEdgeType, FlowNodeType } from "@wire/shared";
import { Edge, Node, NodeProps, useEdges } from "@xyflow/react";
import { memo, useMemo } from "react";

export interface SwitchNodeCompProps extends BaseCustomNodeComponentProps {}

function SwitchNode(
  props: NodeProps<Node<SwitchNodeProps>> & SwitchNodeCompProps
) {
  const edges = useEdges();

  const childEdges: Edge<MatchEdgeProps>[] = useMemo(
    () =>
      edges.filter(
        (e) => e.source == props.id && e.type == FlowEdgeType.SWITCH
      ),
    [edges, props.id]
  );

  const warnings = useMemo(() => {
    const out: string[] = [];
    if (childEdges.length == 0) {
      out.push("At least one switch edge required");
    } else if (!childEdges.some((e) => e.data?.defaultEdge)) {
      out.push("At least one switch edge must be a default edge");
    }
    return out;
  }, [childEdges]);

  return (
    <BaseCustomNode
      {...props}
      warnings={warnings}
      data={{ ...props.data, type: FlowNodeType.SWITCH }}
    ></BaseCustomNode>
  );
}

const SwitchNodeMemo = memo(SwitchNode);

export { SwitchNodeMemo as SwitchNode };
