import { ActionEdgeEditor } from "@/components/flow/editor/edge/action-edge-editor";
import { MatchEdgeEditor } from "@/components/flow/editor/edge/match-edge-editor";
import { SwitchEdgeEditor } from "@/components/flow/editor/edge/switch-edge-editor";
import { ActionNodeEditor } from "@/components/flow/editor/node/action-node-editor";
import { AnnotationNodeEditor } from "@/components/flow/editor/node/annotation-node-editor";
import { JumpNodeEditor } from "@/components/flow/editor/node/jump-node-editor";
import { MatchNodeEditor } from "@/components/flow/editor/node/match-node-editor";
import { SwitchNodeEditor } from "@/components/flow/editor/node/switch-node-editor";
import { QueryPanel } from "@/components/flow/editor/query";
import { BaseEditorProps } from "@/components/flow/flow.types";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { components } from "@/lib/api.types";
import { cn } from "@/lib/utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FlowEdgeType, FlowNodeType } from "@wire/shared";
import { Edge, Node, Panel } from "@xyflow/react";
import { memo, useCallback, useMemo } from "react";
function EditorPanel({
  onClose,
  onEdgeUpdate,
  onNodeUpdate,
  onEdgeCreate,
  onNodeCreate,
  onEdgeDelete,
  onNodeDelete,
  selectedNode,
  show,
  selectedEdge,
  onSelectedDetection,
  selectedDetection,
}: {
  show: boolean;
  onClose: () => void;
  onEdgeDelete: (id: string) => void;
  onNodeDelete: (id: string) => void;
  onEdgeUpdate: (id: string, data: Partial<Edge>) => void;
  onNodeUpdate: (id: string, data: Partial<Node>) => void;
  onEdgeCreate: (data: Partial<Edge>) => void;
  onNodeCreate: (data: Partial<Node>) => void;
  onSelectedDetection?: (detection: components["schemas"]["Detection"]) => void;
  selectedNode: Node | undefined;
  selectedEdge: Edge | undefined;
  selectedDetection: components["schemas"]["Detection"] | undefined;
}) {
  const updateNodeData = useCallback(
    (updatedData: Record<string, any>) => {
      if (selectedNode != null) {
        onNodeUpdate(selectedNode.id, {
          data: updatedData,
        });
      }
    },
    [selectedNode]
  );

  const updateEdgeData = useCallback(
    (updatedData: Record<string, any>) => {
      if (selectedEdge != null) {
        onEdgeUpdate(selectedEdge.id, {
          data: updatedData,
        });
      }
    },
    [onEdgeUpdate]
  );

  const updateEdge = useCallback(
    (updatedEdge: Partial<Edge>) => {
      if (selectedEdge != null) {
        onEdgeUpdate(selectedEdge.id, updatedEdge);
      }
    },
    [onEdgeUpdate]
  );

  const updateNode = useCallback(
    (updatedNode: Partial<Node>) => {
      if (selectedNode != null) {
        onNodeUpdate(selectedNode.id, updatedNode);
      }
    },
    [onNodeUpdate]
  );

  const subPanel = useMemo(() => {
    const baseProps: BaseEditorProps = {
      onClose,
      updateNodeById: onNodeUpdate,
      updateEdgeById: onEdgeUpdate,
      createEdge: onEdgeCreate,
      createNode: onNodeCreate,
      deleteNodeById: onNodeDelete,
      deleteEdgeById: onEdgeDelete,
      selectedDetection,
    };
    if (selectedNode != null) {
      switch (selectedNode.type as FlowNodeType) {
        case FlowNodeType.MATCH:
          return (
            <MatchNodeEditor
              {...baseProps}
              selectedNode={selectedNode}
              updateData={updateNodeData}
              updateNode={updateNode}
            />
          );
        case FlowNodeType.SWITCH:
          return (
            <SwitchNodeEditor
              {...baseProps}
              selectedNode={selectedNode}
              updateData={updateNodeData}
              updateNode={updateNode}
            />
          );
        case FlowNodeType.ACTION:
          return (
            <ActionNodeEditor
              {...baseProps}
              selectedNode={selectedNode}
              updateData={updateNodeData}
              updateNode={updateNode}
            />
          );
        case FlowNodeType.JUMP:
          return (
            <JumpNodeEditor
              {...baseProps}
              selectedNode={selectedNode}
              updateData={updateNodeData}
              updateNode={updateNode}
            />
          );
        case FlowNodeType.ANNOTATION:
          return (
            <AnnotationNodeEditor
              {...baseProps}
              selectedNode={selectedNode}
              updateData={updateNodeData}
              updateNode={updateNode}
            />
          );
      }
    }
    if (selectedEdge != null) {
      switch (selectedEdge.type as FlowEdgeType) {
        case FlowEdgeType.MATCH:
          return (
            <MatchEdgeEditor
              {...baseProps}
              selectedEdge={selectedEdge}
              selectedDetection={selectedDetection}
              updateData={updateEdgeData}
              updateEdge={updateEdge}
            />
          );
        case FlowEdgeType.ACTION:
          return (
            <ActionEdgeEditor
              {...baseProps}
              selectedEdge={selectedEdge}
              selectedDetection={selectedDetection}
              updateData={updateEdgeData}
              updateEdge={updateEdge}
            />
          );
        case FlowEdgeType.SWITCH:
          return (
            <SwitchEdgeEditor
              {...baseProps}
              selectedEdge={selectedEdge}
              selectedDetection={selectedDetection}
              updateData={updateEdgeData}
              updateEdge={updateEdge}
            />
          );
        default:
          return null;
      }
    }
  }, [
    selectedNode,
    selectedEdge,
    onClose,
    onEdgeCreate,
    onNodeCreate,
    updateNodeData,
    updateEdgeData,
    onNodeUpdate,
    onEdgeUpdate,
    onNodeDelete,
    onEdgeDelete,
  ]);

  const showQueryPanel = useMemo(() => {
    if (selectedNode != null) {
      switch (selectedNode.type as FlowNodeType) {
        case FlowNodeType.MATCH:
        case FlowNodeType.SWITCH:
          return true;
        default:
          return false;
      }
    }
    if (selectedEdge != null) {
      switch (selectedEdge.type as FlowEdgeType) {
        default:
          return false;
      }
    }
    return false;
  }, [selectedNode, selectedEdge]);
  if (subPanel == null) return;
  return (
    <Panel
      position="top-right"
      className={cn(
        "max-w-[500px] w-full h-full overflow-auto flex flex-col !m-0 gap-4"
      )}
    >
      <Card className={cn({ hidden: !show })}>
        <CardHeader className="flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-between">
          <div>
            <CardTitle>Edit {selectedNode == null ? "Edge" : "Node"}</CardTitle>
            <CardDescription>
              Update the properties for this{" "}
              {selectedNode == null ? "edge" : "node"}
            </CardDescription>
          </div>
          <div>
            <Button variant="outline" size="icon" onClick={onClose}>
              <XMarkIcon className="w-4 h-4 cursor-pointer" onClick={onClose} />
            </Button>
          </div>
        </CardHeader>
        {subPanel}
      </Card>
      <QueryPanel
        selectedEdge={selectedEdge}
        show={showQueryPanel}
        selectedNode={selectedNode}
        onSelectedDetection={onSelectedDetection}
        selectedDetection={selectedDetection}
      />
    </Panel>
  );
}
const EditorPanelMemo = memo(EditorPanel);

export { EditorPanelMemo as EditorPanel };
