import { BaseCustomEditor } from "@/components/flow/editor/base-custom-editor";
import { BaseFooter } from "@/components/flow/editor/base-footer";
import { NodeEditorFooter } from "@/components/flow/editor/utils/node-editor-footer";
import { NodeEditorProps } from "@/components/flow/flow.types";
import { annotationArrowPositions } from "@/components/flow/node/annotation-node";
import { Button } from "@/components/ui/button";
import { CardContent } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { memo, useMemo } from "react";

export interface AnnotationNodeProps extends Record<string, any> {
  annotation?: string;
  borderColor?: string;
  backgroundColor?: string;
  arrowPosition?:
    | "left"
    | "right"
    | "up"
    | "down"
    | "up-right"
    | "down-right"
    | "up-left"
    | "down-left";
}

function AnnotationNodeEditor(props: NodeEditorProps<AnnotationNodeProps>) {
  const colors = useMemo(
    () => [
      { borderColor: "#ef4444", backgroundColor: "#fecaca" },
      { borderColor: "#f97316", backgroundColor: "#fed7aa" },
      { borderColor: "#eab308", backgroundColor: "#fef08a" },
      { borderColor: "#000000", backgroundColor: "#ffffff" },
      { borderColor: "#22c55e", backgroundColor: "#bbf7d0" },
      { borderColor: "#3b82f6", backgroundColor: "#bfdbfe" },
      { borderColor: "#a855f7", backgroundColor: "#e9d5ff" },
    ],
    []
  );
  return (
    <>
      <CardContent>
        <BaseCustomEditor hideQuery {...props}>
          <div className="flex flex-col gap-4">
            <div>
              <Label>Annotation</Label>
              <Textarea
                rows={4}
                value={props.selectedNode?.data.annotation ?? ""}
                onChange={(e) =>
                  props.updateData({ annotation: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col gap-1">
              <Label>Theme</Label>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button size="icon" variant="outline">
                    <span
                      className="w-8 h-8 rounded-md border"
                      style={{
                        backgroundColor:
                          props.selectedNode?.data.backgroundColor,
                        borderColor: props.selectedNode?.data.borderColor,
                      }}
                    ></span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {colors.map((v, idx) => (
                    <DropdownMenuItem
                      key={`color-${idx}`}
                      onClick={() => props.updateNode({ data: v })}
                    >
                      <span
                        key={`color-${idx}`}
                        className="w-full h-4 rounded-md border"
                        style={{
                          backgroundColor: v.backgroundColor,
                          borderColor: v.borderColor,
                        }}
                      ></span>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className="flex flex-col items-start gap-1">
              <Label>Arrow Style</Label>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    {annotationArrowPositions.find(
                      (v) => v.value == props.selectedNode?.data.arrowPosition
                    )?.name ?? "None"}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {annotationArrowPositions.map((v, idx) => (
                    <DropdownMenuItem
                      key={`arrow-${idx}`}
                      onClick={() =>
                        props.updateNode({ data: { arrowPosition: v.value } })
                      }
                    >
                      {v.name}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </BaseCustomEditor>
      </CardContent>
      <BaseFooter onClose={props.onClose}>
        <NodeEditorFooter
          updateNodeById={props.updateNodeById}
          onNodeUpdate={props.updateNode}
          selectedNode={props.selectedNode}
        />
      </BaseFooter>
    </>
  );
}

const AnnotationNodeEditorMemo = memo(AnnotationNodeEditor);

export { AnnotationNodeEditorMemo as AnnotationNodeEditor };
