import { AppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Badge } from "@/components/ui/badge";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { getCaseStatusBadge, getVerdictBadgeVariant } from "@/lib/case";
import { getTimezone } from "@/lib/time";
import { cn } from "@/lib/utils";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import {
  DetectionSubCategory,
  getSubCategoryTitle,
  Status,
  Verdict,
  VerdictConfig,
} from "@wire/shared";
import moment from "moment";
import { useEffect, useState } from "react";

export const Route = createFileRoute("/_application/admin/tim")({
  component: Tim,
});

function CaseFilterItem(
  props: React.PropsWithChildren<{
    activeFilter: Status | undefined;
    filter?: Status;
    onClick?: () => void;
  }>
) {
  return (
    <li
      onClick={props.onClick}
      className={cn(
        "px-3 text-sm text-muted-foreground font-medium rounded-md",
        {
          "bg-background font-semibold text-foreground":
            props.filter == props.activeFilter,
        }
      )}
    >
      {props.children}
    </li>
  );
}

async function getAdminCases(dto: components["schemas"]["SearchCasesDto"]) {
  const result = await apiClient.POST("/admin/cases/search", {
    body: dto,
  });
  if (result.error != null) {
    throw result.error;
  }
  return result.data;
}

export default function Tim() {
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({ orderBy: "createdAt", orderDir: "desc" });
  const [status, setStatus] = useState<Status | undefined>(undefined);
  const casesQuery = useQuery({
    queryKey: ["ADMIN-CASES", searchSettings],
    queryFn: () => getAdminCases(searchSettings),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    setSearchSettings({
      ...searchSettings,
      statuses: status ? [status] : undefined,
    });
  }, [status]);

  return (
    <AppLayout>
      <TableCard
        onUpdate={(v) => setSearchSettings({ ...searchSettings, ...v })}
        query={casesQuery}
        onClick={(row) => {
          window.open(`/cases/${row.sid}?switchTeamId=${row.teamId}`, "_blank");
        }}
        headers={[
          {
            display: "ID",
            key: "sid",
            sortable: true,
          },
          {
            display: "Client",
            key: "teamName",
          },
          {
            display: "Category",
            key: "subcategories",
            format(value: DetectionSubCategory[], row) {
              let hoverBadge: React.ReactNode = null;
              if (value != null && value.length > 1) {
                const subCats = value.slice(1);
                hoverBadge = (
                  <HoverCard openDelay={0} closeDelay={50}>
                    <HoverCardTrigger>
                      <Badge
                        variant={getVerdictBadgeVariant(
                          row.verdict as any,
                          row.status as any
                        )}
                      >
                        +{subCats.length}
                      </Badge>
                    </HoverCardTrigger>
                    <HoverCardContent>
                      <ul>
                        {subCats.map((v) => (
                          <li key={v}>{getSubCategoryTitle(v)}</li>
                        ))}
                      </ul>
                    </HoverCardContent>
                  </HoverCard>
                );
              }
              return (
                <div className="flex flex-row gap-1">
                  <Badge
                    variant={getVerdictBadgeVariant(
                      row.verdict as any,
                      row.status as any
                    )}
                  >
                    {getSubCategoryTitle(value[0])}
                  </Badge>
                  {hoverBadge}
                </div>
              );
            },
          },
          {
            display: "Name",
            key: "name",
            sortable: true,
          },
          {
            display: "Status",
            key: "status",
            sortable: true,
            format: (value) => getCaseStatusBadge(value),
          },
          {
            display: `Detected At (${getTimezone()})`,
            key: "firstDetectionSourceDetectedAt",
            sortable: true,
            dateTime: true,
          },
          {
            display: `Ingested At (${getTimezone()})`,
            key: "createdAt",
            sortable: true,
            dateTime: true,
          },
          {
            display: "MTTR",
            key: "mttr",
            format(value, row) {
              if (value == null || row.status != Status.CLOSED) return "-";
              return moment.duration(value, "seconds").humanize();
            },
          },
          {
            display: "",
            key: "id",
            format(value, row) {
              let text = "";
              if (row.testMode) {
                text =
                  "This case was ingested in test mode and may have exaggerated numbers for MTTR and MTTD.";
              } else if (row.reingested) {
                text =
                  "This case was reingested and may have exaggerated numbers for MTTR and MTTD.";
              } else {
                return;
              }
              return (
                <div className="flex items-center justify-center">
                  <HoverCard closeDelay={50} openDelay={0}>
                    <HoverCardTrigger className="ml-1">
                      <InformationCircleIcon className="h-6 w-6 text-red-500" />
                    </HoverCardTrigger>
                    <HoverCardContent className="whitespace-normal font-normal">
                      {text}
                    </HoverCardContent>
                  </HoverCard>
                </div>
              );
            },
          },
        ]}
      >
        <>
          <CardHeader className="pb-2">
            <CardTitle>Cases</CardTitle>
            <CardDescription>
              Cases from all clients in your portfolio.
            </CardDescription>
          </CardHeader>
          <CardContent className="pb-2">
            <div className="flex w-full lg:items-stretch flex-col gap-2 lg:flex-row">
              <ul className="bg-background shadow-sm border p-2 rounded-md flex flex-col lg:flex-row w-full items-center lg:w-auto lg:items-start">
                <CaseFilterItem key="all-filter" activeFilter={status}>
                  All
                </CaseFilterItem>
                <CaseFilterItem
                  key="processing-filter"
                  onClick={() => setStatus(Status.PROCESSING)}
                  filter={Status.PROCESSING}
                  activeFilter={status}
                >
                  Processing
                </CaseFilterItem>
                <CaseFilterItem
                  key="escalated-filter"
                  onClick={() => setStatus(Status.ESCALATED)}
                  filter={Status.ESCALATED}
                  activeFilter={status}
                >
                  Escalated
                </CaseFilterItem>
                <CaseFilterItem
                  key="closed-filter"
                  onClick={() => setStatus(Status.CLOSED)}
                  filter={Status.CLOSED}
                  activeFilter={status}
                >
                  Closed
                </CaseFilterItem>
              </ul>
              <div>
                <Select
                  onValueChange={(v) =>
                    setSearchSettings({
                      ...searchSettings,
                      verdict: v == "any" ? undefined : (v as Verdict),
                    })
                  }
                >
                  <SelectTrigger className="h-full bg-background font-medium min-w-[180px]">
                    <SelectValue
                      key="select-value"
                      placeholder="Filter By Verdict"
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem key="any" value="any">
                      Any
                    </SelectItem>
                    {Object.values(VerdictConfig).map((v) => (
                      <SelectItem key={v.display} value={v.type}>
                        {v.display}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </CardContent>
        </>
      </TableCard>
    </AppLayout>
  );
}
