import { defaultAnnotationNodeProps } from "@/components/flow/node/annotation-node";
import { Button } from "@/components/ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import {
  ArrowRightIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { FlowNodeType } from "@wire/shared";
import {
  Handle,
  Node,
  NodeProps,
  Position,
  useEdges,
  useNodes,
  useReactFlow,
} from "@xyflow/react";
import { useTheme } from "next-themes";
import { memo, useMemo } from "react";

export interface BaseCustomNodeDataProps extends Record<string, any> {
  title?: string;
  description?: string;
  type?: FlowNodeType;
  // Is this the primary node of the flow?
  entryNode?: boolean;
  simulationInvalid?: boolean;
  blur?: boolean;
  noEndHandle?: boolean;
  noStartHandle?: boolean;
  simulationEndNode?: boolean;
  simulating?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  enableJump?: boolean;
  jumpToNodeId?: string;
}

export function getDefaultNodePropsByType(type?: FlowNodeType) {
  switch (type) {
    case FlowNodeType.ANNOTATION:
      return { ...defaultAnnotationNodeProps };
    default:
      return {
        backgroundColor: "#ffffff",
        borderColor: "#000000",
      };
  }
}

export interface BaseCustomNodeComponentProps {
  noEndHandle?: boolean;
  noStartHandle?: boolean;
  footer?: string;
  optionalTitle?: boolean;
  warnings?: string[];
  isConnectableEnd?: boolean;
  isConnectableStart?: boolean;
  hideTitle?: boolean;
  updateNode: (
    id: string,
    updatedNode: Partial<Node<BaseCustomNodeDataProps>>
  ) => void;
}

function BaseCustomNode(
  props: React.PropsWithChildren<
    NodeProps<Node<BaseCustomNodeDataProps>> & BaseCustomNodeComponentProps
  >
) {
  const { fitView } = useReactFlow();
  const nodes = useNodes();
  const edges = useEdges();
  const selected = useMemo(
    () => nodes.some((v) => v.selected && v.id == props.id),
    [props.id, nodes]
  );
  const data = useMemo(() => props.data, [props]);
  const title = useMemo(() => {
    if (props.hideTitle) return null;
    if (data.title && data.title.length > 0) {
      return data.title;
    }
    if (props.optionalTitle) return null;
    return "Provide a Title";
  }, [data, props.optionalTitle]);
  const jumpToContent = useMemo(() => {
    const node: Node<BaseCustomNodeDataProps> | undefined = nodes.find(
      (v) => v.id == data.jumpToNodeId
    );
    const edge = edges.find((v) => v.source == props.id);
    if (node == null || edge == null) {
      return (
        <div className="flex items-center gap-1">
          <div className="text-xs  text-muted-foreground">
            Select Jump Location
          </div>
        </div>
      );
    }
    return (
      <div className="flex items-center gap-1">
        <div className="text-xs  text-muted-foreground">
          Jumps to "{node.data.title ?? node.data.query ?? ""}"
        </div>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            void fitView({ nodes: [node], duration: 500, maxZoom: 1 });
            props.updateNode(props.id, { selected: false });
            props.updateNode(node.id, { selected: true });
          }}
          variant="outline"
          size="icon"
        >
          <ArrowRightIcon className="h-3 w-3" />
        </Button>
      </div>
    );
  }, [data.jumpToNodeId, nodes]);

  const showJumpTo = useMemo(() => {
    return data.type == FlowNodeType.JUMP;
  }, [data.enableJump, data.jumpToNodeId]);

  const theme = useTheme();

  const mainContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        {title && <div className="text-sm">{title}</div>}
        {data.description && data.description.length > 0 && (
          <div className="text-xs text-muted-foreground">
            {data.description}
          </div>
        )}
        {props.children}
        <div className={cn("flex justify-between gap-4 flex-row")}>
          <p className="text-xs text-muted-foreground flex-wrap">
            {props.footer}
          </p>
          <p
            style={{
              color:
                theme.resolvedTheme == "dark"
                  ? data.backgroundColor
                  : data.borderColor,
            }}
            className={cn(`text-xs`)}
          >
            {data.type}
          </p>
        </div>
      </div>
    );
  }, [
    title,
    props.children,
    theme.resolvedTheme,
    data.description,
    props.footer,
    data.type,
  ]);

  const warnings = useMemo(() => {
    if (props.warnings == null || props.warnings.length == 0) return;
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className="absolute rounded-full p-1 -top-3 bg-yellow-200 dark:bg-yellow-500 -left-3"
      >
        <HoverCard openDelay={50} closeDelay={50}>
          <HoverCardTrigger asChild>
            <ExclamationTriangleIcon className="h-4 text-destructive w-4" />
          </HoverCardTrigger>
          <HoverCardContent>
            {props.warnings.map((v) => (
              <div key={v}>{v}</div>
            ))}
          </HoverCardContent>
        </HoverCard>
      </div>
    );
  }, [props.warnings]);

  const content = useMemo(() => {
    if (showJumpTo) {
      return jumpToContent;
    }
    return mainContent;
  }, [showJumpTo, jumpToContent, mainContent]);

  const showStartHandle = useMemo(() => {
    return (
      !props.data.entryNode && !props.noStartHandle && !props.data.noStartHandle
    );
  }, [props.data.entryNode, props.noStartHandle, props.data.noStartHandle]);

  const showEndHandle = useMemo(() => {
    return !props.noEndHandle && !props.data.noEndHandle;
  }, [props.noEndHandle, props.data.noEndHandle]);

  const styleOverrides = useMemo(() => {
    if (
      props.data.simulating ||
      props.data.simulationEndNode ||
      (props.warnings != null && props.warnings.length)
    ) {
      return;
    }
    return {
      backgroundColor:
        theme.resolvedTheme == "dark" ? data.borderColor : data.backgroundColor,
      borderColor:
        theme.resolvedTheme == "dark" ? data.backgroundColor : data.borderColor,
    };
  }, [
    props.data.simulating,
    props.data.simulationEndNode,
    props.warnings,
    theme.resolvedTheme,
    data.borderColor,
    data.backgroundColor,
  ]);
  return (
    <div
      style={styleOverrides}
      className={cn(
        "max-w-[300px] border cursor-pointer border-foreground rounded-md p-2",
        {
          "!border-primary border-2": selected,
          "!border-blue-500 dark:!border-blue-200 !bg-blue-200 dark:!bg-blue-500":
            props.data.simulating,
          "!border-green-500 dark:!border-green-200 !bg-green-200 dark:!bg-green-500":
            props.data.simulationEndNode || props.data.entryNode,
          "!border-red-500 dark:!border-red-500 !bg-red-200 dark:!bg-red-500":
            (props.warnings != null && props.warnings.length > 0) ||
            props.data.simulationInvalid,
        }
      )}
    >
      {warnings}
      {showStartHandle && (
        <Handle
          className="p-1"
          type="target"
          position={Position.Top}
          id={`b`}
        />
      )}

      {props.data.blur ? <Placeholder {...data} /> : content}
      {showEndHandle && (
        <Handle
          isConnectableEnd={props.isConnectableEnd ?? true}
          isConnectableStart={props.isConnectableStart ?? true}
          type="source"
          className={cn("p-1", {})}
          position={Position.Bottom}
          id={"a"}
        />
      )}
    </div>
  );
}

const Placeholder = (props: {
  backgroundColor?: string;
  borderColor?: string;
}) => (
  <div
    className="flex w-[100px] flex-col gap-1"
    style={{
      backgroundColor: props.backgroundColor,
      borderColor: props.borderColor,
    }}
  >
    <Skeleton className="w-full h-4" />
    <Skeleton className="w-full h-4" />
    <Skeleton className="w-full h-4" />
  </div>
);

const BaseCustomNodeMemo = memo(BaseCustomNode);

export { BaseCustomNodeMemo as BaseCustomNode };
