import { differenceInMilliseconds, differenceInSeconds } from "date-fns";
import moment from "moment";

function verifyDate(val: string | number) {
  const date = moment(val);
  if (date.isValid()) {
    return true;
  }
  return false;
}

interface DateTimeOptions {
  format?: string;
}

const DefaultDateTimeOptions: DateTimeOptions = {
  format: "MMMM Do YYYY @ h:mm:ss a",
};

export function localDateTime(
  val: string | number,
  opts: DateTimeOptions = DefaultDateTimeOptions
) {
  if (!verifyDate(val)) {
    return "N/A";
  }
  return moment.utc(val).local().format(opts.format);
}

export function utcDateTime(
  val: string | number,
  opts: DateTimeOptions = DefaultDateTimeOptions
) {
  if (!verifyDate(val)) {
    return "N/A";
  }
  const out = moment.utc(val).format(opts.format);
  return `${out} UTC`;
}

export function localDateTimeBuilder(val: string | number) {
  const date = moment(val);
  if (date.isValid()) {
    return moment.utc(val).local();
  }
  return moment("NaN");
}

export function utcDateTimeBuilder(val: string | number) {
  const date = moment(val);
  if (date.isValid()) {
    return moment.utc(val);
  }
  return moment("NaN");
}

export function getTimezone() {
  return new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];
}

export function calculateTTRSeconds(start: string, end: string) {
  return differenceInSeconds(end, start);
}

export function calculateTTRMilliseconds(start?: string, end?: string) {
  if (!start || !end) return;
  return differenceInMilliseconds(end, start);
}
