import { BaseCustomEdgeDataProps } from "@/components/flow/edge/base-custom-edge";
import { BaseCustomEditor } from "@/components/flow/editor/base-custom-editor";
import { BaseFooter } from "@/components/flow/editor/base-footer";
import { NodeEditorFooter } from "@/components/flow/editor/utils/node-editor-footer";
import { NodeEditorProps } from "@/components/flow/flow.types";
import { BaseCustomNodeDataProps } from "@/components/flow/node/base-custom-node";
import { CardContent } from "@/components/ui/card";
import { Combobox } from "@/components/ui/combo-box";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Edge, Node, useEdges, useNodes } from "@xyflow/react";
import { memo, useCallback, useMemo } from "react";

export interface JumpNodeProps extends BaseCustomNodeDataProps {
  showEdge?: boolean;
}

function JumpNodeEditor(props: NodeEditorProps<JumpNodeProps>) {
  const nodes: Node<BaseCustomNodeDataProps>[] = useNodes();
  const edges: Edge<BaseCustomEdgeDataProps>[] = useEdges();
  const edge: Edge<BaseCustomEdgeDataProps> | undefined = useMemo(() => {
    return edges.find((v) => v.source === props.selectedNode?.id);
  }, [edges, props.selectedNode?.id]);

  const showEdge = useMemo(() => {
    return !edge?.data?.hidden;
  }, [edge]);

  const nodeOptions = useMemo(() => {
    return nodes
      .filter((v) => !v.data.jumpToNodeId && v.data.title)
      .map((v) => ({
        label: v.data.title ?? v.data.query ?? "",
        value: v.id,
      }));
  }, [nodes]);

  const createEdge = useCallback(
    (toId: string) => {
      props.createEdge({
        source: props.selectedNode.id,
        target: toId,
        hidden: false,
        type: "jump",
      });
    },
    [props.selectedNode.id]
  );

  const updateJumpTo = useCallback(
    (id: string) => {
      if (edge != null) {
        props.updateEdgeById(edge.id, { target: id });
      } else {
        createEdge(id);
      }
      props.updateNode({ data: { jumpToNodeId: id } });
    },
    [edge, props.updateNode, props.updateEdgeById, createEdge]
  );

  const updateEdge = useCallback(
    (v: boolean) => {
      if (edge == null) return;
      props.updateEdgeById(edge.id, {
        data: { hidden: !v },
      });
    },
    [edge, props.updateEdgeById]
  );

  if (props.selectedNode == null) return null;
  return (
    <>
      <CardContent>
        <BaseCustomEditor hideTitle hideQuery {...props}>
          <div className="flex flex-col gap-1">
            <Label className="text-xs">Jump To</Label>
            <Combobox
              placeholder="Select Node"
              values={nodeOptions}
              value={
                edge == null ? "" : props.selectedNode.data.jumpToNodeId ?? ""
              }
              defaultValue={props.selectedNode.data.jumpToNodeId ?? ""}
              emptyMessage="No nodes"
              onSelect={updateJumpTo}
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label className="text-xs">Show Edge</Label>
            <Switch checked={showEdge} onCheckedChange={updateEdge} />
          </div>
        </BaseCustomEditor>
      </CardContent>
      <BaseFooter onClose={props.onClose}>
        <NodeEditorFooter
          onNodeUpdate={props.updateNode}
          updateNodeById={props.updateNodeById}
          selectedNode={props.selectedNode}
        />
      </BaseFooter>
    </>
  );
}

const JumpNodeEditorMemo = memo(JumpNodeEditor);

export { JumpNodeEditorMemo as JumpNodeEditor };
