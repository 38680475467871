import BaseCustomEdge, {
  BaseCustomEdgeComponentProps,
} from "@/components/flow/edge/base-custom-edge";
import { SwitchEdgeProps } from "@/components/flow/editor/edge/switch-edge-editor";
import { getVerdictConfigByVerdict } from "@wire/shared";
import { Edge, type EdgeProps } from "@xyflow/react";
import { memo, useMemo } from "react";

export interface ActionEdgeCompProps extends BaseCustomEdgeComponentProps {}

function ActionEdge(
  props: EdgeProps<Edge<SwitchEdgeProps>> & ActionEdgeCompProps
) {
  const warnings = useMemo(() => {
    if (props.data?.verdict == null) {
      return ["Verdict required"];
    }
  }, [props.data?.verdict]);

  const title = useMemo(() => {
    if (props.data?.verdict == null) return "Please select a verdict";
    return getVerdictConfigByVerdict(props.data?.verdict)?.display ?? "";
  }, [props.data?.verdict]);

  return (
    <BaseCustomEdge {...props} warnings={warnings}>
      {title}
    </BaseCustomEdge>
  );
}

const ActionEdgeMemo = memo(ActionEdge);

export { ActionEdgeMemo as ActionEdge };
