import { ErrorPage } from "@/components/error-page";
import Intercom from "@intercom/messenger-js-sdk";
import { keepPreviousData, useQueries } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import {
  GLOBAL_TEAM_QUERY_KEY,
  GLOBAL_TEAMS_QUERY_KEY,
  INTEGRATION_METADATA_QUERY_KEY,
  queryClient,
} from "@/lib/tanstack";
import { routeTree } from "@/routeTree.gen";
import { createRouter } from "@tanstack/react-router";
import moment from "moment";
import { ThemeProvider } from "./components/theme-provider";
import { apiClient, getUser, setAPIToken } from "./lib/api";

export const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: {
    queryClient,
    integrationMetadata: undefined!,
    user: undefined!,
    team: undefined!,
    teams: undefined!,
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const user = getUser();

async function getTeam() {
  const response = await apiClient.GET("/team");
  // This means they have a valid JWT but for some reason the team or role it's associated with is no longer valid
  // so let's toss them back to the login screen
  if (!response.response.ok) {
    if (response.response.status == 404) {
      setAPIToken(undefined);
      window.location.href = "/login";
    } else if (response.response.status == 409) {
      throw new Error(response.error?.message);
    }
  }
  if (response.data != null) {
    return response.data;
  }
  return null;
}

async function getTeams() {
  const response = await apiClient.GET("/team/all");
  if (response.data != null) {
    return response.data;
  }
  return null;
}

async function getIntegrationMetadata() {
  const response = await apiClient.GET("/integration/config");
  if (response.data != null) {
    return response.data;
  }
  return null;
}

function App() {
  const [
    { data: team, isLoading: teamIsLoading, error: teamError },
    { data: teams, isLoading: teamsIsLoading, error: teamsError },
    {
      data: integrationMetadata,
      isLoading: integrationMetadataIsLoading,
      error: integrationMetadataError,
    },
  ] = useQueries(
    {
      queries: [
        {
          queryKey: [GLOBAL_TEAM_QUERY_KEY],
          queryFn: getTeam,
          refetchOnWindowFocus: false,
        },
        {
          queryKey: [GLOBAL_TEAMS_QUERY_KEY],
          queryFn: getTeams,
          retry: false,
          refetchOnWindowFocus: false,
          placeholderData: keepPreviousData,
        },
        {
          queryKey: [INTEGRATION_METADATA_QUERY_KEY],
          queryFn: getIntegrationMetadata,
          retry: false,
          refetchOnWindowFocus: false,
          placeholderData: keepPreviousData,
        },
      ],
    },
    queryClient
  );
  useEffect(() => {
    if (team == null) return;
    (async () => {
      const userData = await apiClient.GET("/users");
      if (userData.data != null && team != null) {
        window.document.title = `Wirespeed · ${team.name}`;
        Intercom({
          app_id: "ev1yns59",
          user_id: user.id,
          Role: user.role,
          hide_default_launcher: true,
          company: {
            id: user.parentTeamId,
            name: team.id == user.parentTeamId ? team.name : undefined,
            "service-provider": team.serviceProvider,
          },
          user_hash: userData.data.intercomId,
          name: userData.data.fullName,
          email: user.email,
          created_at: moment(userData.data.createdAt).unix(),
        });
      }
    })();
  }, [team]);

  if (teamIsLoading || teamsIsLoading || integrationMetadataIsLoading) {
    return (
      <div className="h-screen w-screen flex items-end text-gray-100 dark:text-neutral-900 pb-4 text-xs justify-center">
        {
          "-[------->+<]>+++.+[--->+<]>.-.-------.-[--->+<]>--.++[--->++<]>.---.[->++++++<]>.+[->+++<]>.--[--->+<]>-.---[->++++<]>.------------.++++++++++.-------------.----.--[--->+<]>-.-----------.--.------.--[--->+<]>-.-----.+++."
        }
      </div>
    );
  }

  if (teamError != null) {
    return <ErrorPage error={teamError.message} />;
  }
  if (teamsError != null) {
    return <ErrorPage error={teamsError.message} />;
  }

  return (
    <StrictMode>
      <ThemeProvider
        attribute="class"
        defaultTheme="system"
        storageKey="doc_theme"
        disableTransitionOnChange
      >
        <RouterProvider
          router={router}
          context={{
            queryClient,
            user,
            team: team!,
            teams: teams!,
            integrationMetadata: integrationMetadata!,
          }}
        />
      </ThemeProvider>
    </StrictMode>
  );
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
}
