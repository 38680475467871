import { AppLayout } from "@/components/app-layout";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Link, useRouteContext } from "@tanstack/react-router";
import moment from "moment";
import numeral from "numeral";

import {
  ExclamationTriangleIcon,
  UserIcon,
  WrenchIcon,
} from "@heroicons/react/16/solid";
import { CheckBadgeIcon, ClockIcon } from "@heroicons/react/24/outline";

import IntegrationIdentity from "@/components/integration/integration-identity";
import { IntegrationLogo } from "@/components/settings/add-integration";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { HoverCard, HoverCardContent } from "@/components/ui/hover-card";
import { Logo } from "@/components/ui/logo";
import { localDateTimeBuilder } from "@/lib/time";
import { cn } from "@/lib/utils";
import { DashboardData } from "@/routes/_application/dashboard";
import { HoverCardTrigger } from "@radix-ui/react-hover-card";
import { useMemo } from "react";
import { Area, AreaChart, XAxis } from "recharts";

const chartConfig = {
  desktop: {
    label: "Week",
    color: "hsl(var(--chart-1))",
  },
  count: {
    label: "Cases",
  },
} satisfies ChartConfig;

export default function Dashboard({
  caseCount,
  weeklyCases,
  closedCases,
  mttr,
  mttd,
  recentVIPs,
  mttv,
  integrations,
}: DashboardData) {
  const { user, team, integrationMetadata } = useRouteContext({
    from: "/_application/dashboard",
  });

  const formattedWeeklyCases = useMemo(() => {
    return weeklyCases.map((v) => ({
      count: v.count,
      week: moment(v.week).format("MMM DD"),
    }));
  }, [weeklyCases]);

  return (
    <AppLayout>
      <main className="flex flex-1 flex-col gap-4 md:gap-8">
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:gap-8 xl:grid-cols-4">
          <Link to={"/cases"} search={{ filter: "ESCALATED" }}>
            <Card className="hover:shadow-md h-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Escalated Cases
                </CardTitle>
                <HealthIcon
                  icon={ExclamationTriangleIcon}
                  health={((10 - caseCount.count) / 10) * 100}
                />
              </CardHeader>
              <CardContent>
                <div className={cn("text-2xl font-bold")}>
                  {caseCount.count}
                </div>
              </CardContent>
            </Card>
          </Link>
          <HoverCard openDelay={50} closeDelay={50}>
            <HoverCardTrigger asChild>
              <Link to="/cases">
                <Card className="hover:shadow-md h-full">
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Mean Time To Verdict
                    </CardTitle>

                    <HealthIcon
                      icon={Logo}
                      fill
                      health={
                        mttv.average < 2_000
                          ? 100
                          : mttv.average > 10_000
                            ? 0
                            : 75
                      }
                    />
                  </CardHeader>
                  <CardContent>
                    {mttv.average == null ? (
                      <div className="text-2xl font-bold">N/A</div>
                    ) : (
                      <>
                        <div className="text-2xl font-bold">
                          {mttv.average > 10_000
                            ? moment.duration(mttv.average).humanize()
                            : `${numeral(mttv.average).format("0,0")}ms`}
                        </div>
                        <p className="text-xs text-muted-foreground">
                          {mttv.average > 10_000
                            ? `${numeral(mttv.average).format()} ms`
                            : ""}
                          {mttv.average > 10_000 && mttv.change != null
                            ? " | "
                            : ""}
                          {mttv.change != null &&
                            `${numeral(mttv.change).format("+0[a]%")} from last month`}
                        </p>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Link>
            </HoverCardTrigger>
            <HoverCardContent>
              MTTV is calculated from the time Wirespeed ingests an alert to the
              time a verdict is made.
            </HoverCardContent>
          </HoverCard>
          <HoverCard openDelay={50} closeDelay={50}>
            <HoverCardTrigger asChild>
              <Link to="/cases">
                <Card className="hover:shadow-md h-full">
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Mean Time To Detect
                    </CardTitle>

                    <HealthIcon
                      icon={ClockIcon}
                      health={
                        mttd.average < 120
                          ? 100
                          : mttd.average > 60 * 60
                            ? 0
                            : 75
                      }
                    />
                  </CardHeader>
                  <CardContent>
                    {mttd.average == null ? (
                      <div className="text-2xl font-bold">N/A</div>
                    ) : (
                      <>
                        <div className="text-2xl font-bold">
                          {moment.duration(mttd.average * 1000).humanize()}
                        </div>
                        <p className="text-xs text-muted-foreground">
                          {numeral(mttd.average).format()} seconds
                          {mttd.change != null &&
                            ` | ${numeral(mttd.change).format("+0[a]%")} from last month`}
                        </p>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Link>
            </HoverCardTrigger>
            <HoverCardContent>
              MTTD is calculated from the time an event happens to the time a
              verdict is made.
            </HoverCardContent>
          </HoverCard>
          <HoverCard openDelay={50} closeDelay={50}>
            <HoverCardTrigger asChild>
              <Link to="/cases">
                <Card className="hover:shadow-md h-full">
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Mean Time To Respond
                    </CardTitle>

                    <HealthIcon
                      icon={WrenchIcon}
                      health={
                        mttr.average < 120
                          ? 100
                          : mttr.average > 60 * 60
                            ? 0
                            : 75
                      }
                    />
                  </CardHeader>
                  <CardContent>
                    {mttr.average == null ? (
                      <div className="text-2xl font-bold">N/A</div>
                    ) : (
                      <>
                        <div className="text-2xl font-bold">
                          {moment.duration(mttr.average * 1000).humanize()}
                        </div>
                        <p className="text-xs text-muted-foreground">
                          {numeral(mttr.average).format()} seconds
                          {mttr.change != null &&
                            ` | ${numeral(mttr.change).format("+0[a]%")} from last month`}
                        </p>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Link>
            </HoverCardTrigger>
            <HoverCardContent>
              MTTR is calculated from the time an event happens to the time the
              case is closed.
            </HoverCardContent>
          </HoverCard>
        </div>
        <Card>
          <CardHeader>
            <CardTitle>Trends</CardTitle>
            <CardDescription>Cases over the last 3 months</CardDescription>
          </CardHeader>
          <CardContent className="flex items-center justify-center">
            {formattedWeeklyCases.length < 2 ? (
              <div className="italic text-muted-foreground">
                Not enough data yet. Check back soon!
              </div>
            ) : (
              <ChartContainer
                className="w-full h-24 min-h-24"
                config={chartConfig}
              >
                <AreaChart
                  accessibilityLayer
                  data={formattedWeeklyCases}
                  margin={{
                    top: 6,
                    bottom: 2,
                  }}
                >
                  <XAxis
                    dataKey="week"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                  />
                  <ChartTooltip
                    cursor={false}
                    labelFormatter={(label) => `Week of ${label}`}
                    content={<ChartTooltipContent indicator="line" />}
                  />
                  <Area
                    dataKey="count"
                    label="Count"
                    type="basis"
                    fill="var(--color-desktop)"
                    fillOpacity={0.4}
                    stroke="var(--color-desktop)"
                  />
                </AreaChart>
              </ChartContainer>
            )}
          </CardContent>
        </Card>
        <div className="grid gap-4 md:gap-8 grid-cols-1 xl:grid-cols-3">
          <Card>
            <CardHeader>
              <CardTitle>Recently Closed Cases</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-2 max-h-96 overflow-auto">
              {closedCases.data.length == 0 && (
                <div className="flex flex-col space-y-8 items-center">
                  <img
                    src="/illustrations/no-cases.svg"
                    className="w-auto h-[256px]"
                  />
                  <h3 className="text-xl">No closed cases yet</h3>
                </div>
              )}
              {closedCases.data.map((v) => (
                <Link key={v.id} to="/cases/$caseId" params={{ caseId: v.id }}>
                  <div className="grid lg:grid-cols-4 grid-cols-1 hover:bg-muted p-2 rounded-md items-center gap-4">
                    <div className="col-span-4 flex space-x-2">
                      <CheckBadgeIcon className="h-9 w-9 flex-shrink-0 text-green-500" />
                      <div className="flex flex-col gap-1 justify-center">
                        <p className="text-sm font-semibold">{v.title}</p>
                        <p className="text-sm break-words hyphens-auto w-full text-muted-foreground">
                          {v.name}
                        </p>
                      </div>
                    </div>
                    {v.closedAt && (
                      <div className="flex-shrink-0 text-muted-foreground lg:text-right text-sm">
                        {localDateTimeBuilder(v.closedAt).fromNow()}
                      </div>
                    )}
                  </div>
                </Link>
              ))}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Integrations</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-2 max-h-96 overflow-auto">
              {integrations.data.length == 0 && (
                <div className="flex flex-col space-y-8 items-center">
                  <img
                    src="/illustrations/lost.svg"
                    className="w-auto h-[256px]"
                  />
                  <h3 className="text-xl">No integrations yet</h3>
                </div>
              )}
              {integrations.data.map((v) => {
                return (
                  <Link
                    key={v.id}
                    to="/settings/integrations/$integrationId"
                    params={{ integrationId: v.id }}
                  >
                    <div className="flex flex-col hover:bg-muted p-2 gap-1 rounded-md">
                      <div className="items-center flex space-x-2">
                        <IntegrationLogo
                          className="max-h-6 max-w-6"
                          config={integrationMetadata.find(
                            (metadata) => metadata.slug === v.platform
                          )}
                        />
                        <div className="grid gap-1">
                          <p className="text-sm font-medium leading-none">
                            {
                              integrationMetadata.find(
                                (metadata) => metadata.slug === v.platform
                              )?.name
                            }
                          </p>
                        </div>
                      </div>
                      <IntegrationIdentity
                        identityFields={v.identityFields}
                        integrationPlatform={v.platform}
                      />
                    </div>
                  </Link>
                );
              })}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Recently Discovered VIPs</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-2 max-h-96 overflow-auto">
              {recentVIPs.data.length == 0 && (
                <div className="flex flex-col space-y-8 items-center">
                  <img
                    src="/illustrations/empty-void.svg"
                    className="w-auto h-[256px]"
                  />
                  <h3 className="text-xl">No VIPs yet</h3>
                </div>
              )}
              {recentVIPs.data.map((v) => (
                <Link
                  key={v.id}
                  to="/assets/users/$userId"
                  params={{ userId: v.id }}
                >
                  <div className="grid lg:grid-cols-3 grid-cols-1 hover:bg-muted p-2 rounded-md items-center gap-4">
                    <div className="col-span-2 flex space-x-2">
                      <UserIcon className="h-9 w-9 flex-shrink-0 text-primary" />
                      <div className="grid gap-1">
                        <p className="text-sm font-medium leading-none">
                          {v.displayName}
                        </p>
                        <p className="text-sm text-muted-foreground">
                          {v.title ?? "-"}
                        </p>
                      </div>
                    </div>
                    <div className="flex-shrink-0 text-muted-foreground lg:text-right text-sm">
                      {localDateTimeBuilder(v.createdAt).fromNow()}
                    </div>
                  </div>
                </Link>
              ))}
            </CardContent>
          </Card>
        </div>
      </main>
    </AppLayout>
  );
}

function HealthIcon(props: {
  health: number;
  fill?: boolean;
  icon: React.FC<{ className?: string }>;
}) {
  return (
    <props.icon
      className={cn("h-4 w-4 text-muted-foreground", {
        "text-green-500": props.health == 100,
        "text-yellow-500": props.health >= 75 && props.health < 100,
        "text-red-500": props.health < 75,
        "fill-green-500": props.fill && props.health == 100,
        "fill-yellow-500":
          props.fill && props.health >= 75 && props.health < 100,
        "fill-red-500": props.fill && props.health < 75,
      })}
    />
  );
}
