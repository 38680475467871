import { BaseCustomEdgeDataProps } from "@/components/flow/edge/base-custom-edge";
import { BaseCustomEditor } from "@/components/flow/editor/base-custom-editor";
import { BaseFooter } from "@/components/flow/editor/base-footer";
import { EdgeLogger } from "@/components/flow/editor/utils/edge-logger";
import { EdgeStyleTypeSwitcher } from "@/components/flow/editor/utils/edge-switcher";
import { EdgeEditorProps } from "@/components/flow/flow.types";
import { CardContent } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { getVerdictConfigByVerdict, Verdict } from "@wire/shared";
import { memo } from "react";

export interface ActionEdgeProps extends BaseCustomEdgeDataProps {
  verdict?: Verdict;
}

function ActionEdgeEditor(props: EdgeEditorProps<ActionEdgeProps>) {
  return (
    <>
      <CardContent>
        <BaseCustomEditor hideDescription hideQuery hideTitle {...props}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Label>Verdict</Label>
              <Select
                onValueChange={(e) =>
                  props.updateData({ verdict: e as Verdict })
                }
                value={props.selectedEdge?.data?.verdict}
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder={
                      props.selectedEdge?.data?.verdict ??
                      "Please select a verdict"
                    }
                  ></SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {Object.values(Verdict).map((v) => (
                    <SelectItem key={v} value={v}>
                      {getVerdictConfigByVerdict(v)?.display ?? v}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <EdgeLogger
              selectedEdge={props.selectedEdge}
              onEdgeUpdate={props.updateEdge}
              selectedDetection={props.selectedDetection}
            />
          </div>
        </BaseCustomEditor>
      </CardContent>
      <BaseFooter
        className="items-start justify-normal"
        onClose={props.onClose}
      >
        <EdgeStyleTypeSwitcher
          selectedEdge={props.selectedEdge}
          onEdgeUpdate={props.updateEdge}
        />
      </BaseFooter>
    </>
  );
}

const ActionEdgeEditorMemo = memo(ActionEdgeEditor);

export { ActionEdgeEditorMemo as ActionEdgeEditor };
