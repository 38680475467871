import { Edge, Node } from "@xyflow/react";

export function getAllAncestorNodesInFlow(
  nodes: Node[],
  edges: Edge[],
  currentNodeId: string
): Node[] {
  const chain = [currentNodeId];
  const out: Node[] = [];
  let currentNode = nodes.find((n) => n.id === currentNodeId);

  while (currentNode != null) {
    // Find edges where current node is the target
    const incomingEdges = edges.filter((e) => e.target === currentNode?.id);

    if (incomingEdges.length === 0) {
      // No more incoming edges, we've reached the start
      break;
    }

    // Get the source node of the first incoming edge
    const sourceNode = nodes.find((n) => n.id === incomingEdges[0].source);
    if (sourceNode == null) break;
    out.unshift(sourceNode);
    currentNode = sourceNode;
  }

  return out;
}
