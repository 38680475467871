import { BaseCustomEdgeDataProps } from "@/components/flow/edge/base-custom-edge";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { TemplateStringInput } from "@/components/ui/template-string-input";
import { components } from "@/lib/api.types";
import { getDisplayFromTemplateString } from "@wire/shared";
import { Edge } from "@xyflow/react";
import { memo, useMemo } from "react";

function EdgeLogger(props: {
  selectedEdge: Edge<BaseCustomEdgeDataProps> | undefined;
  selectedDetection?: components["schemas"]["Detection"] | null;
  onEdgeUpdate: (edge: Partial<Edge>) => void;
}) {
  const parsedLog = useMemo(() => {
    if (props.selectedDetection == null || props.selectedEdge == null) {
      return null;
    }
    if (!props.selectedEdge.data?.log?.includes("`")) return;
    return getDisplayFromTemplateString(
      props.selectedEdge.data.log,
      props.selectedDetection
    );
  }, [props.selectedDetection, props.selectedEdge?.data?.log]);

  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col gap-2">
        <Label className="text-xs">Add Timeline Log</Label>
        <Switch
          checked={props.selectedEdge?.data?.shouldLog ?? false}
          onCheckedChange={(v) =>
            props.onEdgeUpdate({ data: { shouldLog: v } })
          }
        />
      </div>
      {props.selectedEdge?.data?.shouldLog && (
        <div className="flex flex-col flex-1 gap-2">
          <Label className="text-xs">Log</Label>
          <TemplateStringInput
            inputObject={props.selectedDetection}
            onChange={(v) => props.onEdgeUpdate({ data: { log: v } })}
            value={props.selectedEdge?.data?.log ?? ""}
          />

          {parsedLog && (
            <p className="text-xs text-muted-foreground">{parsedLog}</p>
          )}
        </div>
      )}
    </div>
  );
}

const EdgeLoggerMemo = memo(EdgeLogger);

export { EdgeLoggerMemo as EdgeLogger };
