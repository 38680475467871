import { defaultMatchEdgeProps } from "@/components/flow/edge/match-edge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { FlowEdgeType } from "@wire/shared";
import {
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  BaseEdge as FlowBaseEdge,
  getBezierPath,
  getSmoothStepPath,
  getStraightPath,
  useEdges,
  useReactFlow,
} from "@xyflow/react";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface BaseCustomEdgeDataProps extends Record<string, any> {
  name?: string;
  description?: string;
  curveType?: "bezier" | "straight" | "smoothstep" | "step";
  shouldLog?: boolean;
  log?: string;
  blur?: boolean;
  labelPosition?: number; // Position along path from 0 to 1
  simulating?: boolean;
  simulationInvalid?: boolean;
  hidden?: boolean;
}

export function getDefaultEdgePropsByType(type?: FlowEdgeType) {
  switch (type) {
    case FlowEdgeType.MATCH:
      return { ...defaultMatchEdgeProps };
    default:
      return {
        backgroundColor: "#ffffff",
        borderColor: "#000000",
      };
  }
}
export interface BaseCustomEdgeComponentProps {
  warnings?: string[];
  updateEdge: (
    id: string,
    updatedEdge: Partial<Edge<BaseCustomEdgeDataProps>>
  ) => void;
}
export default function BaseCustomEdge(
  props: React.PropsWithChildren<
    EdgeProps<Edge<BaseCustomEdgeDataProps>> & BaseCustomEdgeComponentProps
  >
) {
  const edges = useEdges();
  const selected = useMemo(
    () => edges.some((v) => v.selected && v.id == props.id),
    [props.id, edges]
  );
  const { flowToScreenPosition } = useReactFlow();

  const [isDragging, setIsDragging] = useState(false);
  const [dragStartTime, setDragStartTime] = useState<number | null>(null);

  const [path, labelX, labelY] = useMemo(() => {
    let pathFunction = getBezierPath;
    if (props.data?.curveType === "straight") {
      pathFunction = getStraightPath;
    } else if (props.data?.curveType === "step") {
      pathFunction = getSmoothStepPath;
    }

    const [pathString, x, y] = pathFunction({
      sourceX: props.sourceX,
      sourceY: props.sourceY,
      targetX: props.targetX,
      targetY: props.targetY,
    });

    // Calculate position along the path based on labelPosition
    if (props.data?.labelPosition != null) {
      const pathElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      pathElement.setAttribute("d", pathString);
      const pathLength = pathElement.getTotalLength();
      const point = pathElement.getPointAtLength(
        pathLength * props.data.labelPosition
      );
      return [pathString, point.x, point.y];
    }

    // Default to center position if no labelPosition specified
    return [pathString, x, y];
  }, [
    props.data?.curveType,
    props.data?.labelPosition,
    props.sourceX,
    props.sourceY,
    props.targetX,
    props.targetY,
  ]);

  const onDragStart = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setIsDragging(true);
    setDragStartTime(Date.now());
  }, []);

  const onDrag = useCallback(
    (event: MouseEvent) => {
      if (!isDragging || dragStartTime == null) return;
      // Ignore drag events for first 100ms
      if (Date.now() - dragStartTime < 100) return;
      const pathElement: SVGPathElement | null = document.getElementById(
        `path-${props.id}`
      ) as any;
      if (pathElement == null) return;

      const pathLength = pathElement.getTotalLength();
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      // Find closest point on path
      let minDistance = Infinity;
      let closestPoint = 0;

      // Sample points along the path to find closest point
      const numSamples = 200; // Number of points to sample
      const step = Math.round(pathLength / numSamples);
      for (let i = 0; i <= pathLength; i += step) {
        const pt = pathElement.getPointAtLength(i);
        const screenPt = flowToScreenPosition({ x: pt.x, y: pt.y });
        const distance = Math.sqrt(
          Math.pow(screenPt.x - mouseX, 2) + Math.pow(screenPt.y - mouseY, 2)
        );
        if (distance < minDistance) {
          minDistance = distance;
          closestPoint = i;
        }
      }
      props.updateEdge(props.id, {
        data: {
          ...props.data,
          labelPosition: closestPoint / pathLength,
        },
      });
    },
    [isDragging, dragStartTime, props]
  );
  const onDragEnd = useCallback(() => {
    setIsDragging(false);
    setDragStartTime(null);
  }, []);

  // Add event listeners for drag
  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", onDrag);
      window.addEventListener("mouseup", onDragEnd);
    }
    return () => {
      window.removeEventListener("mousemove", onDrag);
      window.removeEventListener("mouseup", onDragEnd);
    };
  }, [isDragging, onDrag, onDragEnd]);

  const warnings = useMemo(() => {
    if (props.warnings == null || props.warnings.length == 0) return;
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className="absolute rounded-full p-1 -top-3 bg-yellow-200 dark:bg-yellow-500 -left-3"
      >
        <HoverCard openDelay={50} closeDelay={50}>
          <HoverCardTrigger asChild>
            <ExclamationTriangleIcon className="h-4 text-destructive w-4" />
          </HoverCardTrigger>
          <HoverCardContent>
            {props.warnings.map((v) => (
              <div key={v}>{v}</div>
            ))}
          </HoverCardContent>
        </HoverCard>
      </div>
    );
  }, [props.warnings]);

  const label = useMemo(() => {
    if (props.children == null) return;
    return (
      <EdgeLabelRenderer>
        <div
          style={{
            zIndex: 1000,
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
            cursor: isDragging ? "grabbing" : "grab",
          }}
          className={cn(
            "nodrag nopan bg-background rounded-md border border-border p-2",
            {
              "!border-primary": selected,
              "bg-blue-200 border-blue-500 dark:border-blue-200 dark:bg-blue-500":
                props.data?.simulating,
              "!border-red-500 dark:!border-red-500 !bg-red-200 dark:!bg-red-500":
                (props.warnings != null && props.warnings.length > 0) ||
                props.data?.simulationInvalid,
            }
          )}
          onMouseDown={onDragStart}
        >
          {warnings}
          {props.data?.blur ? (
            <Skeleton className="w-[100px] h-4" />
          ) : (
            props.children
          )}
        </div>
      </EdgeLabelRenderer>
    );
  }, [
    props.children,
    props.data,
    isDragging,
    selected,
    warnings,
    labelX,
    labelY,
    props.warnings,
  ]);

  if (props.data?.hidden) return null;
  return (
    <>
      <FlowBaseEdge
        {...props}
        path={path}
        className={cn({
          "!stroke-primary": selected,
          "!stroke-blue-500 dark:!stroke-blue-200": props.data?.simulating,
          "!stroke-red-500 dark:!stroke-red-500 ":
            (props.warnings != null && props.warnings.length > 0) ||
            props.data?.simulationInvalid,
        })}
        id={`path-${props.id}`}
        markerEnd={props.markerEnd}
        style={props.style}
      />
      {warnings}
      {label}
    </>
  );
}
