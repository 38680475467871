import { JumpNodeProps } from "@/components/flow/editor/node/jump-node-editor";
import {
  BaseCustomNode,
  BaseCustomNodeComponentProps,
} from "@/components/flow/node/base-custom-node";
import { FlowNodeType } from "@wire/shared";
import { Node, NodeProps, useEdges } from "@xyflow/react";
import { memo, useMemo } from "react";

export interface JumpNodeCompProps extends BaseCustomNodeComponentProps {}

function JumpNode(props: NodeProps<Node<JumpNodeProps>> & JumpNodeCompProps) {
  const edges = useEdges();
  const edge = useMemo(() => {
    return edges.find((v) => v.source == props.id);
  }, [props.id, edges]);

  const warnings = useMemo(() => {
    const out: string[] = [];
    if (props.data.jumpToNodeId == null) {
      out.push("Jump target required");
    } else if (edge == null) {
      out.push("Jump edge required");
    }
    return out;
  }, [props.data.jumpToNodeId, edge]);

  return (
    <BaseCustomNode
      {...props}
      warnings={warnings}
      hideTitle
      isConnectableStart={props.data.jumpToNodeId == null}
      data={{ ...props.data, type: FlowNodeType.JUMP }}
    ></BaseCustomNode>
  );
}

const JumpNodeMemo = memo(JumpNode);

export { JumpNodeMemo as JumpNode };
