import ManageExclusion from "@/components/manage-exclusion";
import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/settings/automation")({
  component: Automation,
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getExclusionOptions());
  },
});

async function getExclusions(dto: components["schemas"]["PaginationDto"] = {}) {
  const response = await apiClient.POST("/exclusion", {
    body: dto,
  });
  if (response.data == null) {
    throw new Error("No data returned from API");
  }
  return response.data;
}

const EXCLUSION_QUERY_KEY = "exclusions";
function getExclusionOptions(dto: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [EXCLUSION_QUERY_KEY, dto],
    queryFn: () => getExclusions(dto),
    placeholderData: keepPreviousData,
  });
}

function Automation() {
  const [querySettings, setQuerySettings] = useState<
    components["schemas"]["PaginationDto"]
  >({});
  const [createExclusionDialogOpen, setCreateExclusionDialogOpen] =
    useState(false);
  const [updateExclusionDialogOpen, setUpdateExclusionDialogOpen] =
    useState(false);
  const [selectedExclusion, setSelectedExclusion] = useState<
    components["schemas"]["Exclusion"] | null
  >(null);
  const exclusionQuery = useQuery(getExclusionOptions(querySettings));
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!updateExclusionDialogOpen) {
      setSelectedExclusion(null);
    }
  }, [updateExclusionDialogOpen]);

  useEffect(() => {
    if (selectedExclusion != null) {
      setUpdateExclusionDialogOpen(true);
    }
  }, [selectedExclusion]);

  useEffect(() => {
    if (!createExclusionDialogOpen && !updateExclusionDialogOpen) {
      queryClient.invalidateQueries({ queryKey: [EXCLUSION_QUERY_KEY] });
    }
  }, [createExclusionDialogOpen, updateExclusionDialogOpen]);

  async function updateExclusion(
    id: string,
    dto: components["schemas"]["UpdateExclusionDto"]
  ) {
    const response = await apiClient.PATCH("/exclusion/{id}", {
      params: { path: { id } },
      body: dto,
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [EXCLUSION_QUERY_KEY] });
    toast.success("Exclusion updated");
  }

  async function deleteExclusion(id: string) {
    const response = await apiClient.DELETE("/exclusion/{id}", {
      params: { path: { id } },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [EXCLUSION_QUERY_KEY] });
    toast.success("Exclusion deleted");
  }

  return (
    <>
      <ManageExclusion
        title="Create Exclusion"
        description="Automatically close future detections that match this query"
        open={createExclusionDialogOpen}
        onClose={() => setCreateExclusionDialogOpen(false)}
      />
      <ManageExclusion
        title="Update Exclusion"
        description="Update an existing exclusion"
        exclusion={selectedExclusion}
        open={updateExclusionDialogOpen}
        onClose={() => setUpdateExclusionDialogOpen(false)}
      />
      <TableCard
        embedded
        query={exclusionQuery}
        onClick={setSelectedExclusion}
        searchable
        onUpdate={(settings) =>
          setQuerySettings({ ...querySettings, ...settings })
        }
        tableActions={[
          {
            display: "Create Exclusion",
            onClick: () => setCreateExclusionDialogOpen(true),
          },
        ]}
        rowActions={[
          {
            name: (row) => (row.enabled ? "Disable" : "Enable"),
            onClick: (row) =>
              updateExclusion(row.id, { enabled: !row.enabled }),
          },
          {
            name: "Delete",
            onClick: (row) => deleteExclusion(row.id),
          },
        ]}
        headers={[
          {
            key: "sid",
            display: "ID",
            sortable: true,
          },
          {
            key: "name",
            sortable: true,
            display: "Name",
          },
          {
            key: "enabled",
            sortable: true,
            display: "Enabled",
          },
          {
            key: "queryString",
            display: "Query",
            format: (value) => {
              return <span className="font-mono">{value}</span>;
            },
          },
        ]}
      >
        <CardHeader>
          <CardTitle>Exclusions</CardTitle>
          <CardDescription>
            Automatically close detections that match certain criteria
          </CardDescription>
        </CardHeader>
      </TableCard>
    </>
  );
}
