import { BaseCustomEdgeDataProps } from "@/components/flow/edge/base-custom-edge";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Edge } from "@xyflow/react";
import { memo } from "react";

function EdgeStyleTypeSwitcher(props: {
  selectedEdge: Edge<BaseCustomEdgeDataProps> | undefined;
  onEdgeUpdate: (edge: Partial<Edge>) => void;
}) {
  return (
    <div className="flex flex-col gap-2">
      <Label className="text-xs">Curve Type</Label>
      <Select
        key={props.selectedEdge?.id}
        onValueChange={(v) =>
          props.onEdgeUpdate({
            data: { curveType: v as any },
          })
        }
        value={props.selectedEdge?.data?.curveType ?? "smoothstep"}
      >
        <SelectTrigger>
          <SelectValue
            defaultValue={props.selectedEdge?.data?.curveType ?? "smoothstep"}
            placeholder={props.selectedEdge?.data?.curveType ?? "smoothstep"}
          ></SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="straight">straight</SelectItem>
          <SelectItem value="step">step</SelectItem>
          <SelectItem value="smoothstep">smoothstep</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
}

const EdgeStyleTypeSwitcherMemo = memo(EdgeStyleTypeSwitcher);

export { EdgeStyleTypeSwitcherMemo as EdgeStyleTypeSwitcher };
