import BaseCustomEdge, {
  BaseCustomEdgeComponentProps,
} from "@/components/flow/edge/base-custom-edge";
import { MatchEdgeProps } from "@/components/flow/editor/edge/match-edge-editor";
import { Edge, type EdgeProps } from "@xyflow/react";
import { memo } from "react";

export interface MatchEdgeCompProps extends BaseCustomEdgeComponentProps {}

export const defaultMatchEdgeProps: MatchEdgeProps = {
  branch: true,
};

function MatchEdge(
  props: EdgeProps<Edge<MatchEdgeProps>> & MatchEdgeCompProps
) {
  return (
    <BaseCustomEdge {...props}>
      <div className="flex justify-end text-xs font-mono">
        {props.data?.branch ? "True" : "False"}
      </div>
    </BaseCustomEdge>
  );
}

const MatchEdgeMemo = memo(MatchEdge);

export { MatchEdgeMemo as MatchEdge };
