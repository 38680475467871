import BaseCustomEdge, {
  BaseCustomEdgeComponentProps,
} from "@/components/flow/edge/base-custom-edge";
import { SwitchEdgeProps } from "@/components/flow/editor/edge/switch-edge-editor";
import { Edge, type EdgeProps } from "@xyflow/react";
import { memo, useMemo } from "react";

export interface SwitchEdgeCompProps extends BaseCustomEdgeComponentProps {}

function SwitchEdge(
  props: EdgeProps<Edge<SwitchEdgeProps>> & SwitchEdgeCompProps
) {
  const warnings = useMemo(() => {
    const out: string[] = [];
    if (props.data == null) return out;
    if (props.data.defaultEdge) return out;

    if (
      props.data.fieldSelector == null ||
      props.data.fieldSelector.length == 0
    ) {
      out.push("Field selector is required");
    }

    if (!props.data.selectorAsTitle && props.data.title == null) {
      out.push("Title is required");
    }

    return out;
  }, [props.data]);

  const title = useMemo(() => {
    if (props.data?.defaultEdge) return "Default";
    if (props.data?.selectorAsTitle && props.data?.fieldSelector) {
      return <span className="font-mono">{props.data.fieldSelector}</span>;
    }
    if (props.data?.title != null && props.data?.title.length > 0) {
      return props.data.title;
    }
    return "Please provide title";
  }, [props.data]);

  return (
    <BaseCustomEdge {...props} warnings={warnings}>
      {title ?? "Please provide title"}
    </BaseCustomEdge>
  );
}

const SwitchEdgeMemo = memo(SwitchEdge);

export { SwitchEdgeMemo as SwitchEdge };
